<template>
    <div class="custom-styles">
        <div v-html="stylesColors"></div>
        <div v-html="stylesCurrent"></div>
    </div>
</template>

<script>
export default {
    name: 'current-color-styles',
    computed: {
        stylesCurrent() {
            let currentColor = this.$store.state.general.currentColor
            if (!currentColor) {
                currentColor = this.$store.state.general.defaultColor
            }
            let currentColorHEX = currentColor.hex
            let currentColorHEXTextOver = currentColor.hexTextOver
            return `<style>
             .bg-current {background-color:${currentColorHEX}!important; }
             .text-current {color:${currentColorHEX}!important; }
             .text-over-current {color:${currentColorHEXTextOver}!important; }


             .c-btn__square--current {
                border-color: ${currentColorHEX}!important;
                color: ${currentColorHEX}!important;
             }
             .c-btn__square--current * {
                color: ${currentColorHEX}!important;
             }

             .c-btn__square--current:hover, .c-btn__square--current.active {
                 color: ${currentColorHEXTextOver}!important;
                 background: ${currentColorHEX}!important;
             }
             .c-btn__square--current:hover *, .c-btn__square--current.active * {
                color: ${currentColorHEXTextOver}!important;
             }

             .c-btn__text--current:hover, .c-btn__text--current.active {
                color: ${currentColorHEX}!important;
             }
              .text--hover-current:hover, .text--hover-current.active {
                color: ${currentColorHEX}!important;
             }


             .pull-left-current:before {
                background-color:${currentColorHEX}!important;
             }
              .pull-right-current:after {
                background-color:${currentColorHEX}!important;
             }
             </style>`
        },
        stylesColors() {
            let colors = this.$store.state.general.colors
            if (!colors) {
                return ''
            }

            let str = '<style>'

            colors.forEach(c => {
                str += ` .bg-c-${c.name} {background-color:${c.hex}!important;} `
                str += ` .text-c-${c.name} {color:${c.hex}!important;} `
                str += ` .pull-right-c-${c.name}:after {background-color:${c.hex}!important;} `
                str += ` .pull-left-c-${c.name}:before {background-color:${c.hex}!important;} `
            })

            str += '</style>'

            return str
        }
    }
}
</script>