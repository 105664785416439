<template>
    <div class="question md:min-h-content relative" v-if="question">
        <div class="absolute top-0 left-0 right-0 h-48 md:h-full md:bottom-0  z--1 flex flex-row items-center justify-between">
            <div class="w-full md:w-4/12 bg-gray-yellow h-full">
            </div>
            <div class="w-full md:w-6/12 bg-white"></div>
        </div>
        <div ref="questionContainer" class="container mx-auto h-full relative z-2">
            <div class="row items-center justify-between h-full">
                <div class="w-full md:w-5/12 h-full">
                    <div class="h-48 md:min-h-content relative">
                        <div class="parallax-image relative h-full">

                            <div class="absolute inset-0 hidden md:flex flex-col items-center justify-center">
                                <img ref="bgImage" v-if="question.images.bgImage" :src="question.images.bgImage.url"
                                     class="h-auto w-full absolute"/>
                                <img ref="fgImage" v-if="question.images.fgImage" :src="question.images.fgImage.url"
                                     class="h-auto w-full absolute"/>
                            </div>
                            <img v-if="question.images.mobileImage" :src="question.images.mobileImage.url"
                                 class="block md:hidden w-full mobile-image"/>
                        </div>
                        <div class="absolute bottom-0 md:hidden">
                            <div class="o-tag mb-0 transform-50">
                                <span>{{$store.state.quiz.currentQuiz.progressLabel}}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="content-height-issue | w-full mt-12 | md:mt-6"
                     :class="{
                'md:w-6/12':question.answers.length < 5,
                'md:w-7/12':question.answers.length >= 5
                 }"
                >
                    <h2 class="question-title">{{question.title}}</h2>
                    
                    <div 
                        v-if="question.description"
                        class="my-4 opacity-50"
                    >
                        <p v-html="question.description" />
                    </div>
                    <div v-if="questionType === QUESTION_TYPES.NORMAL">
                        <p class="my-4">{{$t('Select the option that fit the most with your values')}}</p>

                        <div class="answers">
                            <div
                                    v-for="answer in question.answers"
                                    :key="`${question.id}-${questionsAnswers[question.id]}-${answer.id}`"
                                    class="c-card c-card__answer"
                                    :class="{'active': questionsAnswers[question.id] === answer.id}"
                                    @click.prevent.stop="selectAnswer(question.id, answer.id)"
                            >
                                <div class="row flex-no-wrap">
                                    <div class="col">
                                        <checkbox color="current"
                                                  :value="questionsAnswers[question.id] === answer.id"></checkbox>
                                    </div>
                                    <div class="col">
                                        {{answer.title}}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="questionType === QUESTION_TYPES.DRAG_AND_DROP">
                        <p class="mt-4">{{$t('Place the statements in the order that matches your vision (drag and drop).')}}</p>
                        <div class="flex flex-col-reverse md:flex-col">
                            <div class="answers">
                                <draggable
                                        v-model="orderAnswers"
                                        :dragOptions="dragOptions">
                                    <div
                                            v-for="(answer, answerIdx) in orderAnswers"
                                            :key="`${answer.id}`"
                                            class="c-card c-card__answer draggable"
                                    >
                                        <div class="row flex-no-wrap">
                                            <div class="col">
                                                <div class="circle border-1 border-black flex flex-col items-center justify-center">
                                                    <span>{{answerIdx+1}}</span>
                                                </div>
                                            </div>
                                            <div class="col">
                                                {{answer.title}}
                                            </div>
                                        </div>
                                    </div>
                                </draggable>
                            </div>
                            <div class="flex flex-row justify-end md:mt-2">
                                <button type="button" class="c-btn c-btn__text c-btn__text--current pr-0"
                                        @click.prevent="resetOptions">
                                    <sprite id="refresh" class="w-4 h-4"></sprite>
                                    <span class="text-12 pl-1">{{$t('Reset')}}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <h1>Question type invalide</h1>
                    </div>

                    <p class="error my-4" v-if="errors['global']">{{errors.global}}</p>


                    <div class="flex flex-row justify-between mt-6">
                        <div>
                            <button type="button" class="c-btn c-btn__text c-btn__text--current"
                                    @click.prevent="goToPrevQuestion"
                                    v-if="!isFirstQuestion">
                                <sprite id="small-arrow-left" color="hover-current" classes="h-2 mr-1"></sprite>
                                <span>
                                    {{$t('Previous question')}}
                                </span>
                            </button>
                        </div>
                        <div>
                            <button type="button" class="c-btn c-btn__square c-btn__square--current"
                                    :class="{'disabled': !isAnswer}"
                                    @click.prevent="goToNextQuestion">
                                <span v-if="isLastQuestion">
                                    {{$t('Results')}}
                                </span>
                                <span v-else>
                                    {{$t('Next question')}}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
import {dynamicRoutes} from "config/routes";
import {isEmpty} from "utils/utils";
import types from 'store/types'
import Checkbox from "components/utils/Checkbox";
import {routes} from "config/routes";
import __ from 'utils/lang'
import Sprite from "components/utils/Sprite";
import {QUESTION_TYPES} from "src/global";

import {EventBus, EVENTS} from "src/event-bus";

const SPEED_TRANSITION = 0.5//seconds

export default {
    name: 'question',
    components: {Sprite, Checkbox, draggable},
    data() {
        return {
            QUESTION_TYPES: QUESTION_TYPES,

            errors: {},
            orderAnswers: [],

            dragOptions: {
                animation: 200,
                group: "title",
                disabled: false,
                ghostClass: "ghost"
            }
        }
    },
    metaInfo() {
        return {
            title: `Question #${this.currentIdx}`,
        }
    },
    computed: {
        currentIdx() {
            if (!this.$route.params.idx) return 1
            return parseInt(this.$route.params.idx)
        },
        question() {
            return this.$store.getters.getQuestionByIdx(this.currentIdx - 1 || 0)
        },
        questionType() {
            return this.question.type
        },
        questionsAnswers() {
            return this.$store.state.quiz.currentQuiz.questionsAnswers
        },
        isFirstQuestion() {
            return this.currentIdx === 1
        },
        isLastQuestion() {
            return this.currentIdx === (this.$store.state.question.questions.length)
        },
        isAnswer() {
            if (this.questionType === QUESTION_TYPES.NORMAL) {
	            
	            const questionID = this.questionsAnswers[this.question.id]
                return !isEmpty(questionID) && (questionID !== -1 && questionID != '-1')
            }

            return true
        }
    },
    watch: {
        '$route.params.idx'() {
            this.onRouteChange();
        }
    },
    created() {
	    
	    if ( !this.$store.state.quiz.currentQuiz.uid ) {
	    	this.$router.push('/')
	    	return
	    }

		// Since RESET_QUIZ pops from everywhere, we check to never have a progressQuestion that is smaller 
		// than the question from our current route (questions/{idx}
		const currentIdx = this.$route.params.idx
		const progressQuestion = this.$store.state.quiz.currentQuiz.progressQuestion
		
		if ( progressQuestion < currentIdx) {
			
	        this.$store.dispatch(types.MODIFY_CURRENT_QUIZ, {
	            progressQuestion: currentIdx,
	        })
		}
        
        this.onRouteChange();
    },
    methods: {
        resetOptions() {
            this.orderAnswers = JSON.parse(JSON.stringify(this.question.answers))
        },
        onRouteChange() {

            let numberOfQuestions = this.$store.state.question.questions.length

            if (isEmpty(this.$route.params.idx)) {
                this.$router.replace(dynamicRoutes.question(1))
                return

            } else if (parseInt(this.$route.params.idx) > (numberOfQuestions)) {
	            
                // Went to far
                this.$router.replace(dynamicRoutes.question(1))
                return
            }

            let progress = (this.currentIdx) * 100 / numberOfQuestions
            let progressLabel = `${__('Questions')} ${(this.currentIdx)} / ${numberOfQuestions}`

            this.$store.dispatch(types.MODIFY_CURRENT_QUIZ, {
                progress: progress,
                progressLabel: progressLabel,
                currentQuestionIdx: this.currentIdx
            })
            this.$store.dispatch(types.SET_CURRENT_COLOR, this.$store.getters.getColorByName(this.question.colorName))

            this.orderAnswers = JSON.parse(JSON.stringify(this.question.answers))

            //fake a resize so we cant check the content size and see if we need to show the fake header to let space for the loading bar
            this.$nextTick(() => {
                EventBus.$emit(EVENTS.FAKE_RESIZE);
            })
        },
        isSelected(questionId, answerId) {
            return this.questionsAnswers[questionId] === answerId
        },
        selectAnswer(questionId, answerId) {
            this.$store.dispatch(types.SELECT_ANSWER, {questionId, answerId})
        },
        goToPrevQuestion() {
            this.errors = {}

            if (this.isFirstQuestion) {
                return
            }

            let nextIdx = parseInt(this.$route.params.idx) - 1

            if (!this.$refs.questionContainer) {
                this.$router.push(dynamicRoutes.question(nextIdx))
                return
            }

            let paddingWidth = window.innerWidth - this.$refs.questionContainer.clientWidth
            let totalPercent = 100

            if (paddingWidth > 0) {
                totalPercent += ((paddingWidth / 2) / this.$refs.questionContainer.clientWidth * 100)

                //add more space
                totalPercent += 2
                totalPercent = Math.ceil(totalPercent)
            }

            TweenLite.set(this.$refs.questionContainer, {xPercent: 0})
            TweenLite.to(this.$refs.questionContainer, SPEED_TRANSITION, {
                xPercent: totalPercent,
                onComplete: () => {
                    this.$router.push(dynamicRoutes.question(nextIdx))
                    this.$nextTick(() => {
                        TweenLite.set(this.$refs.questionContainer, {xPercent: -totalPercent})
                        TweenLite.to(this.$refs.questionContainer, SPEED_TRANSITION, {xPercent: 0, delay: 0.01})

                        if (this.$refs.fgImage) {
                            TweenLite.set(this.$refs.fgImage, {xPercent: 20})
                            TweenLite.to(this.$refs.fgImage, SPEED_TRANSITION, {
                                xPercent: 0,
                                delay: 0.01,
                                ease: Power0.easeIn
                            })
                        }
                    })
                }
            })

            if (this.$refs.fgImage) {
                TweenLite.set(this.$refs.fgImage, {xPercent: 0})
                TweenLite.to(this.$refs.fgImage, SPEED_TRANSITION, {
                    xPercent: -20,
                    ease: Power0.easeOut
                })
            }
        },
        goToNextQuestion() {
            this.errors = {}


            if (this.questionType === QUESTION_TYPES.NORMAL) {
                if (typeof this.questionsAnswers[this.question.id] === 'undefined' || this.questionsAnswers[this.question.id] === -1) {
                    this.errors['global'] = __('Please select an answer')
                    return
                }
            }

            if (this.questionType === QUESTION_TYPES.DRAG_AND_DROP) {
                let answerIds = this.orderAnswers.map(a => a.id)//just get ids
                this.$store.dispatch(types.ORDER_ANSWERS, {questionId: this.question.id, answerIds})
            }

            this.$store.dispatch(types.UPDATE_QUIZ)

            if (this.isLastQuestion) {
                
                // Get current quiz
                const currentQuiz = this.$store.state.quiz.currentQuiz

                this.$store.dispatch(types.COMPLETED_QUIZ, currentQuiz.id)
                    .then((questionnaire) => {
                        this.success = true
                        this.$router.push(dynamicRoutes.result(questionnaire.uid))
                    })

            } else {
	            
	            let nextIdx = parseInt(this.$route.params.idx) + 1
	
	            this.$store.dispatch(types.MODIFY_CURRENT_QUIZ, {
	                progressQuestion: nextIdx,
	            })
				
	            if (!this.$refs.questionContainer) {
	                this.$router.push(dynamicRoutes.question(nextIdx))
	                return
	            }
	
	            let paddingWidth = window.innerWidth - this.$refs.questionContainer.clientWidth
	            let totalPercent = 100
	
	            if (paddingWidth > 0) {
	                totalPercent += ((paddingWidth / 2) / this.$refs.questionContainer.clientWidth * 100)
	
	                //add more space
	                totalPercent += 2
	                totalPercent = Math.ceil(totalPercent)
	            }
	
	            TweenLite.set(this.$refs.questionContainer, {xPercent: 0})
	            TweenLite.to(this.$refs.questionContainer, SPEED_TRANSITION, {
	                xPercent: -totalPercent,
	                ease: Power0.easeOut,
	                onComplete: () => {
	                    this.$router.push(dynamicRoutes.question(nextIdx))
	                    this.$nextTick(() => {
	                        TweenLite.set(this.$refs.questionContainer, {xPercent: totalPercent})
	                        TweenLite.to(this.$refs.questionContainer, SPEED_TRANSITION, {
	                            xPercent: 0,
	                            delay: 0.01,
	                            ease: Power0.easeIn
	                        })
	
	                        if (this.$refs.fgImage) {
	                            TweenLite.set(this.$refs.fgImage, {xPercent: -20})
	                            TweenLite.to(this.$refs.fgImage, SPEED_TRANSITION, {xPercent: 0, delay: 0.01})
	                        }
	                    })
	                }
	            })
	
	            if (this.$refs.fgImage) {
	                TweenLite.set(this.$refs.fgImage, {xPercent: 0})
	                TweenLite.to(this.$refs.fgImage, SPEED_TRANSITION, {
	                    xPercent: 20
	                })
	            }
            }
        }
    }
}
</script>
