<template>
    <div class="block_spacer block relative"
         :class="`py-${size}`"
    ></div>
</template>

<script>
export default {
    name: 'block-spacer',
    props: {
        size: {
            type: String,
            default: '6'
        }
    }
}
</script>