<template>
    <a    
        class="block"
        :href="this.currentLang.url"
    >
        {{ currentLang.longName }}
    </a>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'lang-switcher',
    data: function () {
        return {
            langs: JSON.parse(JSON.stringify(window.__langs__))
        }
    },
    computed: {   
        ...mapState({
            siteLang: state => state.general.currentLang
        }),
        currentLang() {
            return this.langs.find(lang => lang.completeCode !== this.siteLang)
        }
    }
}
</script>