<template>
    <div class="loading-page">
        <div class="initial-loader h-screen w-full">
            <div class="flex flex-col justify-between items-center w-full h-full">
                <div class="absolute inset-0 z--1 flex flex-row items-end justify-between h-full">
                    <div class="w-full md:w-4/12 bg-gray-yellow h-full">
                    </div>
                    <div class="w-full md:w-1/12 bg-white"></div>
                    <div class="w-full mb-12 md:w-3/12 bg-gray-yellow md:h-half-screen"></div>
                    <div class="w-full md:w-4/12 bg-white"></div>
                </div>
                <div class="container mx-auto h-screen relative z-2" ref="questionContainer">
                    <div class="flex flex-col-reverse items-center justify-between  relative p-12 | md:flex-row md:h-full">
                        <div class="w-full h-full my-6 | md:my-0 md:w-9/12 md:max-h-screen">
                            <div class="flex flex-col justify-center h-full">
                                <img alt="boussole entrepreneuriale" ref="bgImage" v-if="loadingImage"
                                     :src="loadingImage"
                                     class=""/>
                            </div>
                        </div>


                        <img ref="logo" alt="boussole" :src="logo"/>

                    </div>
                </div>


                <div class="w-full relative">
                    <loader-bar :animated="false" :progress="progress" :text="loadingText"></loader-bar>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoaderBar from "components/atoms/LoaderBar";
import __ from 'utils/lang'
import {rand, addClass, removeClass} from 'utils/utils'
import logo from 'assets/svg/logos/logo.svg'
import loadingImage from 'assets/images/loadingImage.png'

export default {
    name: 'loading-page',
    components: {LoaderBar},
    props: {
        loadingText: {
            type: String,
            default: __('Loading')
        },
        autostart: {
            type: Boolean,
            default: false
        },
        time: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            logo: logo,
            loadingImage: loadingImage,
            progress: 0
        }
    },
    mounted() {
        if (this.autostart) {
            this.startLoader(this.time);
        }
    },
    methods: {
        startLoader(time = null) {
            if (!time) {
                time = rand(2, 5)
            }

            addClass(document.body, 'no-scroll')
            return new Promise((resolve, reject) => {
                TweenLite.to(this.$data, time, {
                    progress: 100,
                    onComplete: () => {
                        removeClass(document.body, 'no-scroll')
                        resolve();
                    }
                })
            })
        }
    }
}
</script>