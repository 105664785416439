<template>
    <div>
        <go-back-header></go-back-header>
        <div class="w-full bg-gray-yellow  | page-header">
            <div class="container mx-auto">
                <div class="row justify-center items-center">
                    <div class="w-1/2">
                        <img class="page-header__image" :src="image ? image : defaultImageImage"/>
                    </div>
                    <div class="w-1/2">
                        <h1 class="py-4 md:py-8 md:text-65 md:leading-70" v-html="title"></h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import GoBackHeader from "components/atoms/GoBackHeader";
import defaultImageImage from "assets/images/default-header.png"
export default {
    name: 'page-header',
    props: {
        image: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        }
    },
    components: {GoBackHeader},
    created() {
        this.defaultImageImage = defaultImageImage
    }
}
</script>
