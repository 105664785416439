<script>
import Vue from 'vue'
import Builder from 'components/utils/Builder'
import GoBackHeader from 'components/atoms/GoBackHeader'

const Resources = Vue.component('resources', {
    name: 'resources',
    components: {Builder, GoBackHeader},
    delimiters: ['V{', '}'],
    data() {
        return {
	        pageId: 1610,
            fields: null
        }
    },
    metaInfo() {
        return {
            title: this.fields.seo.titleRaw['1'],
        }
    },
    created() {

        try {
            this.fields = JSON.parse(JSON.stringify(window.__pagesData__[this.pageId].fields))
        } catch (e) {
	        console.log(e)
        }
    },
    template: '#template-resources'
})

export default Resources
</script>
