<template>
    <div class="block_content copy" v-html="body"></div>
</template>

<script>
export default {
    name: 'block-content',
    props: {
        body: {
            type: String,
            required: true
        }
    }
}
</script>