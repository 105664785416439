<template>
	<div class="auth-container container-fluid max-width-1200">
		<div class="col-xs-12 col-sm-10 col-md-8 col-lg-8 mx-auto no-gutter">
			<div class="content">
				<transition
					name="fade"
					mode="out-in"
				>
					<router-view></router-view>
				</transition>
			</div>
		</div>
		<img :src="`/assets/combined/images/branchs/branchs-combined.png`"
			 class="img-footer"/>
	</div>
</template>

<script>
export default {
	name:'auth-container'
}
</script>
