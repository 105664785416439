const __ = (str, variables = {}) => {
    if (!window.__translations__) return str

    let currentLang = window.__initialData__.currentLang
    if (window.vueStore) {
        currentLang = window.vueStore.state.general.currentLang
    }

    if (!currentLang) return str

    if (typeof window.__translations__[currentLang][str] !== 'undefined') {
        //languages
        str = window.__translations__[currentLang][str]
    } else {
        let errorMessage = `Translations missing '${str}' for lang:${currentLang}`
        console.warn(errorMessage)
    }

    //set variables
    Object.keys(variables).forEach(varKey => {
        let varString = `{{${varKey}}}`
        let value = variables[varKey]
        if (str.includes(varString)) {
            str = str.replace(varString, value)
        }
    })

    return str
}

export default __
