<script>
import Vue from 'vue'
import Builder from 'components/utils/Builder'
import GoBackHeader from 'components/atoms/GoBackHeader'

const About = Vue.component('about', {
    name: 'about',
    components: {Builder, GoBackHeader},
    delimiters: ['V{', '}'],
    data() {
        return {
	        pageId: 161,
            fields: null
        }
    },
    metaInfo() {
        return {
            title: this.fields.seo.titleRaw['1']
        }
    },
    created() {

        try {
            this.fields = JSON.parse(JSON.stringify(window.__pagesData__[this.pageId].fields))
        } catch (e) {
	        console.log(e)
        }
    },
    template: '#template-about'
})

export default About
</script>
