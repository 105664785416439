import {
    TweenLite,
    CSSPlugin
} from "gsap/all";

// Ensure modules don't get dropped by tree-shaking
const gsap = [
    TweenLite,
    CSSPlugin
];

export default gsap