import {routes} from 'config/routes'
import Home from 'components/pages/Home'
import Question from "components/pages/Question";
import Result from 'components/pages/Result'
import Enterprise from 'components/pages/Enterprise'
import Enterprises from 'components/pages/Enterprises'
import About from 'components/pages/About'
import Resources from 'components/pages/Resources'
import LoadingPage from "components/pages/LoadingPage";


const vueRoutes = [
    {
        name: 'question',
        path: routes.question,
        component: Question,
        meta: {requireQuizEmail: true, showLoaderBar: true, showPageLoader: false}
    },
    {
        name: 'result',
        path: routes.result,
        component: Result,
        meta: {requireQuizEmail: false, showFooter: true, showPageLoader: true}
    },
    {
        name: 'enterprise',
        path: routes.enterprise,
        component: Enterprise,
        meta: {showFooter: true, showPageLoader: true}
    },
    {
        name: 'enterprises',
        path: routes.enterprises,
        component: Enterprises,
        meta: {showFooter: true, showPageLoader: true}
    },
    {
        name: 'about',
        path: routes.about,
        component: About,
        meta: {showFooter: true, showPageLoader: true}
    },
    {
        name: 'loading',
        path: '/loading',
        component: LoadingPage
    },
    {
        name: 'resources',
        path: routes.resources,
        component: Resources,
        meta: {showFooter: true, showPageLoader: true}
    },
    {
        name: 'index',
        path: routes.index,
        component: Home,
        meta: {showLoaderBar: true}
    },
    {
        path: '*',
        redirect: '/'
    }
]

export default vueRoutes
