<template>

    <div class="block_list bg-gray-yellow px-6 py-4 my-2">

        <ul class="items">
            <li
                    v-for="(item, idx) in items"
                    :key="idx"
                    class="item flex flex-row items-center justify-start mb-2">
                <span class="pr-2">
                    <sprite id="arrow-link" color="dark" classes="w-4"></sprite>
                </span>
                <span class=" mb-0 pb-0 text-14 font-bold">
                    {{item}}
                </span>
            </li>

        </ul>
    </div>


</template>

<script>
import Sprite from "components/utils/Sprite";

export default {
    name: 'block-list',
    components: {Sprite},
    props: {
        items: {
            type: Array,
            required: true
        }
    }
}
</script>