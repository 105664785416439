import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import general from './modules/general'
import data from './modules/data'
import question from './modules/question'
import enterprise from './modules/enterprise'
import quiz from './modules/quiz'
Vue.use(Vuex)


//Store is where all the actions, and data are store (save)
const store = new Vuex.Store({
	modules: {
		user,
		general,
		question,
		enterprise,
		data,
		quiz
	},
	strict: process.env.NODE_ENV !== 'production'
})


window.vueStore = store

export default store
