<template>
    <div class="help-btn">
        <button type="button" class="help-btn" @click.prevent.stop="toggleHelpText">
            <sprite id="help"></sprite>
        </button>

        <div class="text-container relative" v-if="isOpen" @click.stop="">
            <div class="o-tooltip">
                <div class="o-tooltip-content">
                    <p class="text">{{$t(text)}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Sprite from "src/components/utils/Sprite";

export default {
    name: 'help-btn',
    components: {Sprite},
    props: {
        text: String
    },
    data: function () {
        return {
            isOpen: false
        }
    },
    created() {
        window.document.addEventListener('keyup', this.keyUp)
        window.document.addEventListener('click', this.close)
    },
    beforeDestroy() {
        window.document.removeEventListener('click', this.close)
        window.document.removeEventListener('keyup', this.keyUp)
    },
    mounted() {
        //always make the parent node relative
        this.$el.parentNode.style.position = 'relative'
    },
    methods: {
        keyUp(e) {
            //escape key
            if (e.keyCode === 27) {
                this.isOpen = false
            }
        },
        toggleHelpText() {
            this.isOpen = !this.isOpen
        },
        close(){
             this.isOpen = false
        }
    }
}
</script>


<style scoped lang="scss">
    .help-btn {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate3d(50%, -50%, 0);
        z-index: 3;

        button {
            -moz-appearance: none;
            -ms-progress-appearance: none;
            -webkit-appearance: none;
            border-radius: 100%;
            padding: 0.1rem;
            background: theme('colors.gray.yellow');
            width: 30px;
            height: 30px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;


            .sprite {
                width: 50%;
                height: 50%;
            }
        }
    }
</style>