<template>

    <div class="block_links">

        <ul class="links">
            <li
                    v-for="(link, idx) in links"
                    :key="link.linkUrl + idx"
                    class="link ">
                <span class="pr-2">
                    <sprite id="arrow-link" color="blue-dark" classes="arrow"></sprite>
                </span>
                <span class="block">
                    <a class="url" target="_blank" :href="link.linkUrl" v-html="link.linkTitle">
                    </a>
                    <span v-if="link.linkSubtitle" class="subtitle">
                        ({{ link.linkSubtitle }})</span>
                </span>

            </li>

        </ul>
    </div>


</template>

<script>
import Sprite from "components/utils/Sprite";

export default {
    name: 'block-links',
    components: {Sprite},
    props: {
        links: {
            type: Array,
            required: true
        }
    }
}
</script>

<style lang="scss">
    .block_links {
        @apply bg-gray-yellow px-6 py-4 my-2;

        .links {
            .link {
                @apply flex flex-row items-start justify-start mb-2;

                .block {
                    .url {
                        @apply text-blue-dark underline font-bold mb-0 pb-0 text-14;
                    }

                    .subtitle {
                        @apply -mt-1 text-black block text-14;
                    }
                }

                .arrow {
                    @apply w-4;
                }

                &:hover {
                    .url, .arrow {
                       @apply text-black;
                    }
                }

            }
        }
    }
</style>