<template>
    <div class="menu">
        <ul class="menu-items" :class="classes">
            <li :class="classesItem">
                <router-link tag="a" :to="$t('/about')" class="">
                    {{$t('About')}}
                </router-link>
            </li>
            <li :class="classesItem">
                <router-link tag="a" :to="$t('/resources')" class="">
                    {{$t('Resources')}}
                </router-link>
            </li>
            <li :class="classesItem">
                <router-link tag="a" :to="$t('/enterprises')" class="">
                    {{$t('Legal Forms')}}
                </router-link>
            </li>
            <li class="-lang-switcher">
                <lang-switcher />
            </li>
        </ul>
        <socials class="desktop-hide"></socials>
    </div>
</template>

<script>
import {dynamicRoutes, routes} from "config/routes";
import Fragment from "components/atoms/Fragment";
import {EventBus, EVENTS} from "src/event-bus";
import Socials from "src/components/atoms/Socials";
import LangSwitcher from "components/atoms/LangSwitch";

export default {
    name: 'vue-menu',
    components: {Socials, LangSwitcher, Fragment},
    props: {
        classes: {
            type: String,
            default: ''
        },
        classesItem: {
            type: String,
            default: ''
        },
        classesBtn: {    
            type: String,
            default: ''
        }
    },

    methods: {
        startQuiz() {
            if (this.$route.path === '/') {
                EventBus.$emit(EVENTS.CLOSE_MENU)
                EventBus.$emit(EVENTS.START_QUIZ)
            } else {
                this.$router.push({path: routes.index, query:{startQuiz:true}})
            }
        },
        continueQuiz() {
            this.$router.push(dynamicRoutes.question(this.$store.state.quiz.currentQuiz.currentQuestionIdx))
        }
    }
}
</script>

<style lang="scss">
    .menu {
        .-lang-switcher {
            padding-top: 2rem;
            padding-bottom: 2rem;
            
            a {
                color: rgba(#000, 0.5);
                &:hover {
                    color: rgba(#000, 1);
                }
            }

            @screen md {
                border-left: 1px solid rgba(#000, 0.15);
                padding-top: 0;
                padding-bottom: 0;
                padding-right: 0;
            }
        }
    }
</style>