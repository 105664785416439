import Vue from 'vue'
import ajax from 'utils/ajax'
import {routes} from 'config/routes'
import types from 'store/types'
import apiActions from 'config/apiActions'
import _ from 'lodash'
//Static

// initial state, with craft backend or empty
const state = {
    currentUser: window.__initialData__.currentUser ? JSON.parse(JSON.stringify(window.__initialData__.currentUser)) : null,
    errors: [],
    validationErrors: {}
}


// getters, make function easy to access by vue
const getters = {}

// actions
const actions = {
    [types.GET_CURRENT_USER](store) {
        return new Promise((resolve, reject) => {
            ajax({
                url: apiActions.auth.getCurrentUser,
                method: 'get',
            })
                .then(data => {
                    store.commit(types.GET_CURRENT_USER, data.user)
                    resolve(data.user)
                })
                .catch(err => {
                    store.commit(types.USER_ERROR, err)
                    reject(err)
                })
        })
    },
    [types.LOGIN_USER](store, loginData) {
        return new Promise((resolve, reject) => {
            if (store.state.currentUser !== null) {
                //logout and recall login with same data
                store.dispatch(types.LOGOUT_USER)
            }
            store.dispatch(types.START_LOADER, 'login')

            loginData.action = 'users/login'
            ajax({
                url: '/',
                method: 'post',
                data: loginData
            })
                .then(data => {
                    if (data.success) {
                        //login was a success, continue
                        store.dispatch(types.GET_CURRENT_USER)
                            .then(() => {
                                store.dispatch(types.FETCH_INITIAL_DATA)
                                    .then(() => {
                                        store.dispatch(types.STOP_LOADER, 'login')
                                        resolve()
                                    })
                            })

                    } else {
                        reject()
                        store.dispatch(types.STOP_LOADER, 'login')
                        store.commit(types.USER_ERROR, [data.error.err])
                    }
                })
                .catch(err => {
                    reject()
                    store.dispatch(types.STOP_LOADER, 'login')
                    store.commit(types.USER_ERROR, [err])
                })
        })
    },

    [types.REGISTER_USER](store, registerData) {
        if (store.state.currentUser != null) {
            store.dispatch(types.LOGOUT_USER, false)
        }
        store.dispatch(types.START_LOADER, 'register')

        registerData.action = 'users/save-user'
        return new Promise((resolve, reject) => {

            ajax({
                url: '/',
                method: 'post',
                data: registerData
            })
                .then(data => {
                    if (data.success) {
                        //login was a success, redirect to dashboard

                        store.dispatch(types.GET_CURRENT_USER)
                            .then(() => {
                                store.dispatch(types.STOP_LOADER, 'register')
                                resolve()
                            })

                    } else {
                        store.dispatch(types.STOP_LOADER, 'register')
                        store.commit(types.USER_ERROR, [data.error.err])
                        reject()
                    }
                })
                .catch(err => {
                    store.dispatch(types.STOP_LOADER, 'register')
                    store.commit(types.USER_ERROR, [err])
                    reject()
                })
        })
    },
    [types.REGISTER_GUEST_USER](store, registerData) {
        if (store.state.currentUser != null) {
            store.dispatch(types.LOGOUT_USER, false)
        }

        return new Promise((resolve, reject) => {

            ajax({
                url: apiActions.auth.registerGuestUser,
                method: 'post',
                data: registerData
            })
                .then(data => {
                    if (data.success) {
                        //login was a success, redirect to dashboard
                        store.commit(types.GET_CURRENT_USER, data.user)
                        store.dispatch(types.CSRF)
                            .then(() => {
                                resolve()
                            })
                    } else {
                        reject()
                    }
                })
                .catch(err => {
                    reject()
                })
        })
    },

    [types.FORGOT_PASSWORD](store, forgotData) {
        store.dispatch(types.START_LOADER, 'forgot-password')
        forgotData.action = 'users/send-password-reset-email'
        return new Promise((resolve, reject) => {
            ajax({
                url: '/',
                method: 'post',
                data: forgotData
            })
                .then(data => {
                    if (data.success) {
                        //login was a success, redirect to dashboard
                        resolve()
                    } else {
                        store.commit(types.USER_ERROR, [data.error.err])
                        reject()
                    }
                    store.dispatch(types.STOP_LOADER, 'forgot-password')
                })
                .catch(err => {
                    store.commit(types.USER_ERROR, [err])
                    store.dispatch(types.STOP_LOADER, 'forgot-password')
                    reject()
                })
        })
    },

    [types.RESET_PASSWORD](store, resetData) {
        store.dispatch(types.START_LOADER, 'set-password')
        resetData.action = 'users/set-password'
        return new Promise((resolve, reject) => {
            ajax({
                url: '/dashboard' + routes.setPassword,
                method: 'post',
                data: resetData
            })
                .then(data => {
                    if (data.success) {
                        //reset was a success, redirect to login
                        window.vueRouter.push(routes.login)
                        resolve()
                    } else {
                        store.commit(types.USER_ERROR, [data.error.err])
                        reject()
                    }
                    store.dispatch(types.STOP_LOADER, 'set-password')
                })
                .catch(err => {
                    store.commit(types.USER_ERROR, [err])
                    store.dispatch(types.STOP_LOADER, 'set-password')
                    reject()

                })
        })

    },

    [types.LOGOUT_USER](store, redirectToLogin) {
        //set to true if undefined
        redirectToLogin = typeof redirectToLogin === 'undefined' ? true : redirectToLogin
        return new Promise((resolve, reject) => {
            ajax({
                url: '/dashboard' + routes.logout,
                method: 'get',
            })
                .then(data => {
                    if (data.success) {
                        store.commit(types.LOGOUT_USER)
                        resolve()
                        if (redirectToLogin) {
                            window.vueRouter.push(routes.login)
                        }

                    } else {
                        reject(data)
                        store.commit(types.USER_ERROR, [data.error.err])
                    }
                })
                .catch(err => {
                    reject(err)
                    store.commit(types.USER_ERROR, [err])
                })
        })
    },
    [types.MODIFY_USER](store, formData) {
        store.dispatch(types.START_LOADER, 'profil')

        //commmit right now so ui can change
        store.commit(types.MODIFY_USER, formData)

        ajax({
            url: apiActions.user.update,
            method: 'post',
            data: {userData: formData}
        })
            .then(data => {
                store.dispatch(types.STOP_LOADER, 'profil')
                if (data.success) {
                    store.commit(types.MODIFY_USER, data.user)
                } else {
                    store.commit(types.USER_ERROR, [data.error.err])
                }
            })
            .catch(err => {
                store.dispatch(types.STOP_LOADER, 'profil')
                store.commit(types.USER_ERROR, [err])
            })
    },
    [types.FINISH_GUEST](store, formData) {
        store.dispatch(types.START_LOADER, 'finish-guest')

        //commmit right now so ui can change
        store.commit(types.MODIFY_USER, formData)

        ajax({
            url: apiActions.auth.finishGuest,
            method: 'post',
            data: {userData: formData}
        })
            .then(data => {
                store.dispatch(types.STOP_LOADER, 'finish-guest')
                if (data.success) {
                    store.commit(types.MODIFY_USER, data.user)
                } else {
                    store.commit(types.USER_ERROR, [data.error.err])
                }
            })
            .catch(err => {
                store.dispatch(types.STOP_LOADER, 'finish-guest')
                store.commit(types.USER_ERROR, [err])
            })
    }
}

// mutations
const mutations = {
    [types.GET_CURRENT_USER](state, user) {
        state.currentUser = user
        state.errors = []
        state.validationErrors = {}
    },
    [types.LOGOUT_USER](state) {
        state.currentUser = null
        state.errors = []
        state.validationErrors = {}
    },
    [types.USER_ERROR](state, err) {
        state.errors = err
    },
    [types.MODIFY_USER](state, formUserData) {
        state.currentUser = _.merge({}, state.currentUser, formUserData)
    },
    [types.ROUTE_CHANGED](state) {
        state.errors = []
    }
}

export default  {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}
