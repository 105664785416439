import Vue from 'vue'
import {hashString} from 'utils/utils'

let customAbortText = '_CUSTOM_ABORT'
let currentRequests = []
const ajax = (data) => {
	//default to post
	if (typeof data.method === 'undefined') {
		data.method = 'post'
	}

	if (typeof data.data === 'undefined') {
		data.data = {}
	}

	if (typeof data.url === 'undefined') {
		data.url = '/'
	}


	//add csrf token
	data.data[window.csrfTokenName] = window.csrfTokenValue
	
	data.data['params'] = {
		...data.data['params'],
		currentSiteId: window.__initialData__.currentSite
	}
	
	//promise based
	return new Promise((resolve, reject) => {
		//make sure we cancel previous ajax call if its the same url
		let hashRequest = hashString(data.url)

		if (typeof currentRequests[hashRequest] !== 'undefined') {
			// abort previous request, if exists
			currentRequests[hashRequest].abort()
			currentRequests[hashRequest].respondWith({
				statusText: customAbortText
			})
			delete currentRequests[hashRequest]
		}

		//do a http request
		Vue.http[data.method](data.url, data.data, {
			emulateJSON: true,
			responseType: 'json',

			before(request) {
				currentRequests[hashRequest] = request
			}
		})
			.then((response) => {
				delete currentRequests[hashRequest]
				if (response.statusText === customAbortText) return reject(new Error(customAbortText))

				const data = response.data
				if (response.status >= 200 && response.status < 300) {
					//success request
					if (typeof data.error !== 'undefined') {
						reject(data.error)
					} else {
						resolve(data)
					}
				} else {
					//err
					reject(data)
				}
			})
			.catch(response => {
				if (response.status === 302) {
					//follow redirect
					window.location.href = response.headers.map['x-redirect']
					return
				}
				if (response.statusText === customAbortText) return reject(new Error(customAbortText))//abort if it was abort
				reject(response)
			})
	})
}

export default  ajax
