<template>
    <footer class="py-6 bg-white">

        <div class="logos mb-12 w-full">
            <footer-logos></footer-logos>
        </div>
        <div class="container mx-auto">
            <div class="row justify-between items-center">
                <div class="w-full sm:w-1/3">
                    <img :src="logo" class="h-8">
                </div>
                <div class="w-full sm:w-2/3">
                    <p class="text-left mt-4 md:mt-0 md:text-right text-gray-dark small">
                        © {{(new Date).getFullYear()}} Boussole Entrepreneuriale | {{$t('All rights reserved')}}
                    </p>
                </div>
            </div>
        </div>

    </footer>
</template>

<script>
import logo from 'assets/svg/logos/logo.svg'
import FooterLogos from 'components/atoms/FooterLogos'

export default {
    name: 'vue-footer',
    components: {FooterLogos},
    data: function () {
        return {
            logo: logo
        }
    }
}
</script>