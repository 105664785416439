<template>
    <div class="homepage | h-full">
        <div class="relative">
            <div class="absolute inset-0 z--1 flex flex-row items-start justify-start h-full">
                <div class="hidden w-full md:w-1/12 bg-white"></div>
                <div class="w-full md:w-4/12 bg-gray-yellow | h-1/3 md:h-screen">
                </div>
            </div>

            <div class="row grid-c | flex flex-col md:flex-row items-center mx-4 md:mx-0 | md:h-screen">

                <div class="col | w-full md:w-1/2 relative">
                    <img :src="homepageIMG" class="homepage-image-header mx-auto w-full md:absolute pin-t pin-l"/>
                </div>

                <div class="col | w-full md:w-1/2 | content-height-issue">
                    <div class="flex flex-col justify-start md:justify-center">
                        <div>
                            <img alt="boussole" :src="logoIMG" class="block my-4 h-8 lg:h-10"/>
                        </div>
                        <h1 v-if="homepageFields" v-html="homepageFields.heading"></h1>
                        <p class="my-6 w-full md:w-5/6" v-if="homepageFields"
                           v-html="homepageFields.description">
                        </p>

                        <loading-container loader-name="create-quiz">
                            <form @submit.prevent="starQuiz" @keyup.enter="starQuiz"
                                  class="mx-auto md:mx-0 mt-4 w-full md:w-8/12 lg:w-1/2">
                                <!--
                                <div class="input-container w-8/12 md:w-full">
                                    <input
                                            :id="`email-homepage-${_uid}`"
                                            type="email"
                                            :placeholder="$t('Email')"
                                            name="email"
                                            :class="{'error' : !!errors['email']}"
                                            v-model="email"
                                    >
                                    <p v-if="errors['email']" class="error">
                                        {{errors['email']}}
                                    </p>
                                    <help-btn
                                            text="By creating an account you will access your last result."></help-btn>
                                </div>

                                <div class="input-container w-8/12 md:w-full">
                                    <div class="select-container" :class="{'error' : !!errors['region']}">
                                        <select :id="`region-homepage-${_uid}`" v-model="regionId">
                                            <option value="-1" selected disabled hidden>{{$t('Choose your region')}}
                                            </option>
                                            <option
                                                    v-for="region in $store.state.data.regions"
                                                    :key="region.id"
                                                    :value="region.id"
                                            >{{region.title}}
                                            </option>
                                        </select>
                                        <sprite id="select-arrow"></sprite>
                                    </div>
                                    <p v-if="errors['region']" class="error">
                                        {{errors['region']}}
                                    </p>
                                </div>
                                -->
                                <div v-if="hasCurrentQuiz" class="mt-6">

                                    <arrow-btn v-on:onClick="loadCurrentQuiz" :label="$t('Continue your quiz')"></arrow-btn>
                                    <span>{{$t('or')}}</span>
                                    <button 
                                        @click.prevent="starQuiz"
                                        type="button" 
                                        class="c-btn c-btn__square | inline-block mt-4 ml-2"
                                    >
                                        {{$t('Start a new one')}}
                                    </button>
                                </div>
                                
                                <div v-else class="mt-6">
                                    <arrow-btn v-on:onClick="starQuiz" :label="$t('Start Quiz')"></arrow-btn>
                                </div>
                            </form>
                        </loading-container>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import {isEmpty} from "utils/utils";
import __ from 'utils/lang'
import {dynamicRoutes, routes} from "config/routes";
import homepageIMG from 'assets/images/loadingImage.png'
import logo from 'assets/svg/logos/logo.svg'
import Sprite from "components/utils/Sprite";
import types from 'store/types'
import ArrowBtn from "components/atoms/btns/ArrowBtn";
import HelpBtn from "components/atoms/HelpBtn";
import LoadingContainer from "components/containers/LoadingContainer";
import {EventBus, EVENTS} from "src/event-bus";

const HOMEPAGE_BACKEND_ID = '4'

export default {
    name: 'homepage',
    components: {LoadingContainer, HelpBtn, ArrowBtn, Sprite},
    data() {
        return {
            homepageIMG: homepageIMG,
            logoIMG: logo,
            errors: {},
        }
    },
    computed: {
        email: {
            get() {
                return this.$store.state.quiz.currentQuiz.email
            },
            set(value) {
                this.errors = {}
                return this.$store.dispatch(types.MODIFY_CURRENT_QUIZ, {email: value})
            }
        },
        regionId: {
            get() {
                return this.$store.state.quiz.currentQuiz.regionId
            },
            set(value) {
                this.errors = {}
                return this.$store.dispatch(types.MODIFY_CURRENT_QUIZ, {regionId: value})
            }
        },
        currentQuiz() {
           return this.$store.state.quiz.currentQuiz
        },
        hasCurrentQuiz() {
			
			if ( this.currentQuiz.questionnaireIsFinish )
				return false

            return this.currentQuiz.uid || false
        }
    },
    metaInfo() {
        return {
            title: this.homepageFields.seo.titleRaw['1'],
        }
    },
    created() {
        //this.$store.dispatch(types.RESET_CURRENT_QUIZ)
        //this.$store.dispatch(types.MODIFY_CURRENT_QUIZ, {progress: 0, progressLabel: __('Introduction')})

        EventBus.$on(EVENTS.START_QUIZ, this.starQuiz)

        try {
            this.homepageFields = JSON.parse(JSON.stringify(window.__pagesData__[HOMEPAGE_BACKEND_ID].fields))
        } catch (e) {
            this.homepageFields = null
        }
    },
    mounted() {
        if (!isEmpty(this.$route.query.startQuiz)) {
            this.starQuiz();
        }
    },
    beforeDestroy() {
        EventBus.$off(EVENTS.START_QUIZ, this.starQuiz)
    },
    methods: {
       
       loadCurrentQuiz() {
         
         const currentQuiz = this.currentQuiz,
              currentQuestion = currentQuiz.progressQuestion

            this.$store.dispatch(types.START_LOADER, 'load-quiz')
            this.$store.dispatch(types.GET_QUIZ_BY_UID, currentQuiz.uid)
                .then(() => {
                    this.$router.push(dynamicRoutes.question(currentQuestion))
                    this.$store.dispatch(types.STOP_LOADER, 'load-quiz')
                })
       },
        starQuiz() {
            this.errors = {}
            /*
            Update v2, not require
            if (isEmpty(this.email)) {
                 this.errors.email = __('Please enter your email')
                 return;
             }
             if (this.regionId === '-1') {
                 this.errors.region = __('Please select your region')
                 return;
             }

             */

            this.$store.dispatch(types.START_LOADER, 'create-quiz')
         this.$store.dispatch(types.MODIFY_CURRENT_QUIZ, {progress: 0, progressLabel: __('Introduction')})
            this.$store.dispatch(types.INIT_QUIZ, {email: this.email, regionId: this.regionId})
                .then(() => {
                    this.$router.push(dynamicRoutes.question(1))
                    this.$store.dispatch(types.STOP_LOADER, 'create-quiz')
                })
        }
    }
}
</script>
