import types from 'store/types'
import ajax from "utils/ajax";
import apiActions from "config/apiActions";
import Vue from 'vue'
// initial state, with craft backend or empty


const currentQuizDefault = {
    id: null,
    uid: null,
    email: '',
    authorId:' -1',

    uri: null,
    url: null,
    
    regionId: '-1',
    ageId: '-1',
    genderId: '-1',
    zipcode:'',

    acceptToShareData: false,
    questionnaireIsFinish: false,
    questionnaireIsSent: false,
    
    questionsAnswers: {},
    bestFitFormEnterprisesIds:[],
    progress: 0,
    currentQuestionIdx: 1,
    progressLabel: '',
    progressQuestion: 1,
    wasLoadedByUid: true//true by default
}

const state = {
    currentQuiz: Object.assign({}, currentQuizDefault),
}

// getters, make function easy to access by vue
//instead
const getters = {}

// actions
const actions = {
    [types.GET_QUIZ_BY_UID](store, uid) {
        return new Promise((resolve, reject) => {
            ajax({
                url: apiActions.quiz.getByUid,
                method: 'get',
                data: {params: {uid: uid}}
            })
                .then(data => {
                    if (data.success) {
                        data.questionnaire.wasLoadedByUid = true
                        resolve(data.questionnaire)
                        store.commit(types.RESET_CURRENT_QUIZ)
                        store.commit(types.MODIFY_CURRENT_QUIZ, data.questionnaire)
                    } else {
                        reject(data)
                    }
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [types.SAVE_BEST_FIT_FORM_ENTERPRISES](store, ids) {

        let data = {
            formEnterprisesIds: ids,
            questionnaireId: store.state.currentQuiz.id
        }

        return new Promise((resolve, reject) => {
            ajax({
                url: apiActions.quiz.saveBestFit,
                method: 'post',
                data
            })
                .then(data => {
                    if (data.success) {
                        resolve(data.questionnaire)
                        store.commit(types.UPDATE_QUIZ, data.questionnaire)
                    } else {
                        reject()
                    }
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [types.INIT_QUIZ](store, data) {
        return new Promise((resolve, reject) => {
            ajax({
                url: apiActions.quiz.create,
                method: 'post',
                data
            })
                .then(data => {
                    if (data.success) {
                        resolve(data.questionnaire)
                        store.commit(types.INIT_QUIZ, data.questionnaire)
                    } else {
                        reject(data)
                    }
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [types.UPDATE_QUIZ](store, data = {}) {
        data.questionsAnswers = store.state.currentQuiz.questionsAnswers
        data.questionnaireId = store.state.currentQuiz.id
        return new Promise((resolve, reject) => {
            ajax({
                url: apiActions.quiz.update,
                method: 'post',
                data
            })
                .then(data => {
                    if (data.success) {
                        resolve(data.questionnaire)
                        store.commit(types.UPDATE_QUIZ, data.questionnaire)
                    } else {
                        reject(data)
                    }
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [types.COMPLETED_QUIZ](store, currentQuizID) {

		let data = {}
        data.questionnaireId = currentQuizID
        
        return new Promise((resolve, reject) => {
            ajax({
                url: apiActions.quiz.completed,
                method: 'post',
                data
            })
                .then(data => {

                    if (data.success) {
                        resolve(data.questionnaire)
                        store.commit(types.COMPLETED, data.questionnaire)
                    } else {
                        reject(data)
                    }
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [types.FINISH_QUIZ](store, data = {}) {
        data.questionsAnswers = store.state.currentQuiz.questionsAnswers
        data.questionnaireId = store.state.currentQuiz.id
        return new Promise((resolve, reject) => {
            ajax({
                url: apiActions.quiz.finish,
                method: 'post',
                data
            })
                .then(data => {
                    if (data.success) {
                        resolve(data.questionnaire)
                        store.commit(types.FINISH_QUIZ, data.questionnaire)
                    } else {
                        reject(data)
                    }
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [types.RESET_CURRENT_QUIZ](store) {
        store.commit(types.RESET_CURRENT_QUIZ)
    },
    [types.MODIFY_CURRENT_QUIZ](store, data) {
        store.commit(types.MODIFY_CURRENT_QUIZ, data)
    },
    [types.SELECT_ANSWER](store, data) {
        store.commit(types.SELECT_ANSWER, data)
    },
    [types.ORDER_ANSWERS](store, data) {
        store.commit(types.ORDER_ANSWERS, data)
    },
}

// mutations
const mutations = {
    [types.INIT_QUIZ](state, data) {

        Vue.set(state.currentQuiz, 'id', data.id)
        Vue.set(state.currentQuiz, 'uid', data.uid)
        Vue.set(state.currentQuiz, 'email', data.email)
        Vue.set(state.currentQuiz, 'authorId', data.authorId)
        Vue.set(state.currentQuiz, 'wasLoadedByUid', false)

        if (window.localStorage) {
            //save quiz so people can see again quiz
            //empty or grab other from storage
            let quizs = JSON.parse(window.localStorage.getItem('quizIds') || '[]')
            quizs.push(data.id)//add quiz
            window.localStorage.setItem('quizIds', JSON.stringify(quizs))//resave data
        }
    },
    [types.UPDATE_QUIZ](state, data) {
        //
    },
    [types.COMPLETED_QUIZ](state, data) {
		//
    },
    [types.FINISH_QUIZ](state, data) {
		//
    },
    [types.MODIFY_CURRENT_QUIZ](state, data) {
        Object.keys(data).forEach(key => {
            //if key exist
            if (typeof state.currentQuiz[key] !== 'undefined') {
                Vue.set(state.currentQuiz, key, data[key])
            }
        })
    },
    [types.RESET_CURRENT_QUIZ](state) {
        state.currentQuiz = Object.assign({}, currentQuizDefault)
    },
    [types.ORDER_ANSWERS](state, data) {
        let questionsAnswers = JSON.parse(JSON.stringify(state.currentQuiz.questionsAnswers))
        questionsAnswers[data.questionId] = data.answerIds

        Vue.set(state.currentQuiz, 'questionsAnswers', questionsAnswers)
    },
    [types.SELECT_ANSWER](state, data) {
        let questionsAnswers = JSON.parse(JSON.stringify(state.currentQuiz.questionsAnswers))
        questionsAnswers[data.questionId] = data.answerId

        Vue.set(state.currentQuiz, 'questionsAnswers', questionsAnswers)
    },
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}
