<template>
    <div class="loader-bar fixed" v-if="progress > -1">
        <div class="loader-bar__bar" ref="bar" :style="`width:${loaderProgress}%;`">
            <div class="o-tag loader-bar-tag"
                 ref="tag"
                 :class="{
                 'o-tag--left': progress === 100,
                 'o-tag--right': progress === 0,
                 'o-tag--bottom': progress > 0 && progress < 100
                 }">
                <span>{{text}}</span>
            </div>
            <div class="loader-bar__bar-progress" :class="`bg-${color}`"></div>

        </div>
        <div class="fake w-full loader-bar-tag">
            <div class="o-tag" ref="text"
                 :class="{
                 'o-tag--left': progress === 100,
                 'o-tag--right': progress === 0,
                 'o-tag--bottom': progress > 0 && progress < 100
                 }">
                <span>{{text}}</span>
            </div>
        </div>
    </div>
</template>

<script>
import __ from 'utils/lang'

export default {
    name: 'loader-bar',
    props: {
        progress: {
            type: Number,
            default: 0,
        },
        text: {
            type: String,
            default: __('Page')
        },
        animated: {
            type: Boolean,
            default: true
        },
        color: {
            type: String,
            default: 'red'
        }
    },
    data: function () {
        return {
            loaderProgress: 0
        }
    },
    watch: {
        'progress'(newValue) {
            //only if on the left side
            if (newValue < 1) {
                this.$nextTick(() => {
                    this.setMinWidth();
                })
            }
            if (this.animated) {
                this.$nextTick(() => {
                    this.tween();
                })
            } else {
                this.loaderProgress = newValue
            }
        },
        'text'() {
            if (this.loaderProgress < 1) {
                this.$nextTick(() => {
                    this.setMinWidth();
                })
            }
        }
    },
    mounted() {
        if (this.animated) {
            this.tween();
        } else {
            this.loaderProgress = this.progress
        }

        this.$nextTick(() => {
            this.setMinWidth();
        })
    },
    methods: {
        setMinWidth() {
            this.$refs.bar.style.minWidth = this.$refs.text.clientWidth + 'px'
        },
        tween() {
            //still to make it work
            if (this.progress > 0 && this.progress < 100) {
                TweenLite.to(this.$refs.tag, 0.1, {xPercent: 50})
            } else {
                TweenLite.to(this.$refs.tag, 0.1, {xPercent: 0})
            }

            let self = this
            TweenLite.to(this.$data, 1, {
                loaderProgress: this.progress,
                onUpdate: function () {
                    if (self.loaderProgress > 0 && self.loaderProgress < 100) {
                        TweenLite.set(self.$refs.tag, {xPercent: 50})
                    } else {
                        TweenLite.set(self.$refs.tag, {xPercent: 0})
                    }
                }
            })
        }
    }
}
</script>