//simple mapping of url
import __ from 'utils/lang'

let routesWithBuilderURI = {}
Object.keys(window.__routesWithBuilder__).forEach(routeKey => {
    routesWithBuilderURI[routeKey] = window.__routesWithBuilder__[routeKey].uri
})

export const routes = Object.assign({
    index: '/',
    question: __('/questions/:idx'),
    result: __('/result/:uid'),
    enterprise: __('/enterprises/:slug'),
    enterprises: __('/enterprises'),
    error: '/404'
}, routesWithBuilderURI)


export const dynamicRoutes = {
    result: (uid) => `${__(`/result/`)}${uid}`,
    question: (idx) => `${__(`/questions/`)}${idx}`,
    enterprise: (slug) => `${__(`/enterprises/`)}${slug}`
}
