<template>
    <div class="result">
        <page-header v-if="fields" :image="fields.image" :title="fields.heading"></page-header>
        <loading-container loader-name="result">

         <!--   <div class="container mx-auto">
                <div class="w-full md:w-1/2">
                    <h1>{{$t('Your result')}}</h1>
                </div>

            </div> -->
            <div
                    v-if="orderFormOfEnterprises.length > 0"
                    v-for="(formOfEnterprise, formOfEnterpriseIdx) in orderFormOfEnterprises"
                    :key="formOfEnterprise.id"
                    class="form-enterprise"
                    :class="{
                      'odd':formOfEnterpriseIdx % 2 === 1,
                        'even':formOfEnterpriseIdx % 2 !== 1
                    }"
            >
                <div class=" form-enterprise--image row">
                    <div class="form-enterprise--image-inner">
                        <img class=" block max-h-content" :src="formOfEnterprise.typeOfEnterprise.image"/>
                    </div>
                </div>
                <div class="form-enterprise--bg"></div>
                <div class="container mx-auto">
                    <div class="row" :class="{'row-reverse':formOfEnterpriseIdx % 2 === 1 }">
                        <div class="w-full md:w-1/2 relative md:min-h-content">
                            <div class="flex flex-row justify-start absolute left-0 right-0 md:relative"
                                 :class="{'justify-end':formOfEnterpriseIdx % 2 === 0 }">
                                <div class=" w-1/3 md:w-2/5 lg:w-1/3">
                                    <div class="square">
                                        <div class="square--content">
                                            <div class="flex flex-col justify-between p-3 lg:p-6 h-full w-full"
                                                 :class="`bg-c-${formOfEnterprise.typeOfEnterprise.colorName}`">
                                                <sprite :id="formOfEnterprise.typeOfEnterprise.spriteId"
                                                        color="white"></sprite>
                                                <h4 class="text-white font-bold">
                                                    {{formOfEnterprise.typeOfEnterprise.title}}</h4>
                                                <sprite id="arrow-right" color="white"></sprite>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <img class="block w-full block md:hidden mt-24 mb-6"
                                 :src="formOfEnterprise.typeOfEnterprise.mobileImage"/>
                        </div>
                        <div class="w-full md:w-1/2">
                            <p class="o-tag o-tag--right">
                                <span v-if="formOfEnterpriseIdx === 0">
                                    {{$t('Primary Result')}}
                                </span>
                                <span v-if="formOfEnterpriseIdx > 0">
                                    {{$t('Secondary Result')}}
                                </span>
                            </p>
                            <h1>{{formOfEnterprise.title}}</h1>
                            <p class="my-6 medium">{{formOfEnterprise.description}}</p>
                            <div class="inline-block">
                                <arrow-btn v-on:onClick="goToFormEnterprise(formOfEnterprise.url)"
                                           label="Discover this form"></arrow-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="bg-gray-yellow py-12" v-if="!isSent">
                <div class="container mx-auto">
                    <div class="row justify-center">
                        <div class="w-full md:w-8/12 lg:w-6/12 mx-auto">
                            <loading-container loader-name="result-form">
                                <h2 class="mb-4">{{$t('Keep your results')}}</h2>

                                <div class="mt-6" v-if="success">
                                    <p>
                                        {{$t('An email containing the link to view your results has just been sent to you. Be careful to check your spam.')}}
                                    </p>
                                </div>
                                
                                <form
                                    v-else
                                    @submit.prevent="submitQuestionnaireForm" 
                                    @keyup.enter="submitQuestionnaireForm"
                                >
                                    <div class="bg-white p-6 md:p-10">
                                        <h6>{{$t('Generals informations')}}</h6>
                                        <div class="input-container w-full">
                                            <input
                                                    :id="`email-result-${_uid}`"
                                                    type="email"
                                                    :placeholder="$t('Email')"
                                                    name="email"
                                                    :class="{'error' : !!errors['email']}"
                                                    v-model="email"
                                                    v-if="$store.state.quiz.currentQuiz.authorId === $store.state.general.defaultCraftUserId"
                                            >
                                            <input
                                                    :id="`email-result-${_uid}`"
                                                    type="email"
                                                    readonly
                                                    :placeholder="$t('Email')"
                                                    name="email"
                                                    :class="{'error' : !!errors['email']}"
                                                    :value="email"
                                                    v-else
                                            >
                                            <p v-if="errors['email']" class="error">
                                                {{errors['email']}}
                                            </p>
                                        </div>
                                        <div class="input-container w-full">
                                            <div class="select-container cursor-pointer"
                                                 :class="{'error' : !!errors['region']}">
                                                <select :id="`region-result-${_uid}`" v-model="regionId">
                                                    <option value="-1" selected disabled hidden>
                                                        {{$t('Choose your region')}}
                                                    </option>
                                                    <option
                                                            v-for="region in $store.state.data.regions"
                                                            :key="region.id"
                                                            :value="region.id"
                                                    >{{region.title}}
                                                    </option>
                                                </select>
                                                <sprite id="select-arrow"></sprite>
                                            </div>
                                            <p v-if="errors['region']" class="error">
                                                {{errors['region']}}
                                            </p>
                                        </div>
                                        <h6>{{$t('Zipcode')}}</h6>
                                        <div class="input-container w-full">
                                            <input
                                                    :id="`email-zipcode-${_uid}`"
                                                    type="text"
                                                    :placeholder="$t('Zipcode')"
                                                    name="zipcode"
                                                    :class="{'error' : !!errors['zipcode']}"
                                                    v-model="zipcode"
                                            >
                                            <p v-if="errors['zipcode']" class="error">
                                                {{errors['zipcode']}}
                                            </p>
                                        </div>
                                        <h6 class="mt-6">{{$t('Gender')}}</h6>
                                        <div class="input-container">
                                            <div class="row justify-start items-center">
                                                <div
                                                        v-for="gender in $store.state.data.genders"
                                                        :key="gender.id"
                                                        class="flex flex-row items-center cursor-pointer"
                                                        @click.prevent.stop="genderId = gender.id"
                                                >
                                                    <checkbox color="black" :value="gender.id === genderId"></checkbox>
                                                    <p class="ml-4 medium">{{gender.title}}</p>
                                                </div>
                                            </div>
                                            <p v-if="errors['gender']" class="error">
                                                {{errors['gender']}}
                                            </p>
                                        </div>

                                        <h6 class="mt-6">{{$t('Age')}}</h6>
                                        <div class="input-container">
                                            <div class="row justify-between items-center grid-c">
                                                <div
                                                        v-for="age in $store.state.data.ages"
                                                        :key="age.id"
                                                        class=" w-1/2 md:w-1/3 flex flex-row items-center cursor-pointer"
                                                        @click.prevent.stop="ageId = age.id"
                                                >
                                                    <checkbox color="black" :value="age.id === ageId"></checkbox>
                                                    <p class="ml-2 md:ml-4 medium">{{age.title}}</p>
                                                </div>
                                            </div>
                                            <p v-if="errors['age']" class="error">
                                                {{errors['age']}}
                                            </p>
                                        </div>


                                    </div>
                                    <div class="flex flex-row cursor-pointer mt-6"
                                         @click.prevent.stop="toggleAcceptToShareData(!acceptToShareData)"
                                    >
                                        <checkbox
                                                color="black"
                                                size="small"
                                                :value="acceptToShareData"></checkbox>
                                        <p class="ml-2 small">
                                            {{$t('I accept my informations can be used by our partners.')}}</p>
                                    </div>
                                    <p v-if="errors['acceptToShareData']" class="error">
                                        {{errors['acceptToShareData']}}
                                    </p>

                                    <div class="mt-6">
                                        <arrow-btn v-on:onClick="submitQuestionnaireForm"
                                                   :label="$t('Send me my results')"></arrow-btn>
                                    </div>
                                </form>
                            </loading-container>
                        </div>
                    </div>
                </div>
            </div>
        </loading-container>
    </div>
</template>

<script>
import types from 'store/types'
import Sprite from "components/utils/Sprite";
import Checkbox from "components/utils/Checkbox";
import __ from 'utils/lang'
import ArrowBtn from "components/atoms/btns/ArrowBtn";
import {isEmpty} from "utils/utils";
import {routes} from "config/routes";
import LoadingContainer from "components/containers/LoadingContainer";
import {calculateBestFitFormOfEnterprise} from "utils/calculations";
import PageHeader from "components/atoms/PageHeader";

const validateQuestionnaireForm = (data) => {
    let errors = {}
    if (isEmpty(data.email)) {
        errors.email = __('Please enter your email')
    }
    if (data.regionId === '-1') {
        errors.region = __('Please select your region')
    }
    if (data.ageId === '-1') {
        errors.age = __('Please select an age')

    }
    if (data.genderId === '-1') {
        errors.gender = __('Please select a gender')

    }
    if (isEmpty(data.zipcode)) {
        errors.zipcode = __('Please enter your zipcode')
    }
    /*
    Not require
    if (!data.acceptToShareData) {
        errors.acceptToShareData = __('Please accept the condition')
    }

     */

    return errors
}

const RESULT_BACKEND_ID = '13306'

export default {
    name: 'result',
    components: {PageHeader, LoadingContainer, ArrowBtn, Checkbox, Sprite},
    data: function () {
        return {
            errors: {},
            fields: null,

            orderFormOfEnterprises: [],
            success: false
        }
    },
    metaInfo() {
        return {
            title: this.fields.seo.titleRaw['1'],
        }
    },
    created() {
        
        if (isEmpty(this.$route.params.uid)) {
            this.$router.replace(routes.index)
            return
        }
        
        // Remove our anonymous user email to have an empty email input field
        if (this.$store.state.quiz.currentQuiz.authorId === this.$store.state.general.defaultCraftUserId) {
            this.$store.dispatch(types.MODIFY_CURRENT_QUIZ, {email: ''})
        }

        if (isEmpty(this.$store.state.quiz.currentQuiz.id)) {
            this.$store.dispatch(types.START_LOADER, 'result')
            this.$store.dispatch(types.GET_QUIZ_BY_UID, this.$route.params.uid)
                .then((questionnaire) => {

                    // If the quiz is not completed, 
                    if ( !questionnaire.questionnaireIsFinish ) {
                        this.$router.replace(routes.index)

                    } else {
                    	this.initQuiz()

                    }
                })
                .catch(() => {
                    this.$router.replace(routes.index)
                })
        }

        // Get default view fiels
        try {
            this.fields = JSON.parse(JSON.stringify(window.__pagesData__[RESULT_BACKEND_ID].fields))
        } catch (e) {
            this.fields = null
        }
    },
    mounted() {

        if (!isEmpty(this.$store.state.quiz.currentQuiz.id)) {
            this.initQuiz()
        }
    },
    computed: {
        email: {
            set(email) {
                return this.$store.dispatch(types.MODIFY_CURRENT_QUIZ, {email: email})
            },
            get() {
                return this.$store.state.quiz.currentQuiz.email
            }
        },
        regionId: {
            get() {
                return this.$store.state.quiz.currentQuiz.regionId
            },
            set(value) {
                return this.$store.dispatch(types.MODIFY_CURRENT_QUIZ, {regionId: value})
            }
        },
        zipcode: {
            get() {
                return this.$store.state.quiz.currentQuiz.zipcode
            },
            set(value) {
                return this.$store.dispatch(types.MODIFY_CURRENT_QUIZ, {zipcode: value})
            }
        },
        ageId: {
            get() {
                return this.$store.state.quiz.currentQuiz.ageId
            },
            set(value) {
                return this.$store.dispatch(types.MODIFY_CURRENT_QUIZ, {ageId: value})
            }
        },
        genderId: {
            get() {
                return this.$store.state.quiz.currentQuiz.genderId
            },
            set(value) {
                return this.$store.dispatch(types.MODIFY_CURRENT_QUIZ, {genderId: value})
            }
        },
        acceptToShareData: {
            get() {
                return this.$store.state.quiz.currentQuiz.acceptToShareData
            }
        },
        isCompleted() {
            return this.$store.state.quiz.currentQuiz.questionnaireIsFinish
        },
        isSent() {
	        return this.$store.state.quiz.currentQuiz.questionnaireIsSent
        },
        wasLoadedByUid() {
            return this.$store.state.quiz.currentQuiz.wasLoadedByUid
        }
    },
    methods: {
        initQuiz() {
            this.$store.dispatch(types.MODIFY_CURRENT_QUIZ, {progress: 100, progressLabel: __('Results')})
            this.$store.dispatch(types.START_LOADER, 'result')

            this.$nextTick(() => {

                //if it was already calculate
                if (this.$store.state.quiz.currentQuiz.bestFitFormEnterprisesIds.length > 0) {
                    let orderFormOfEnterprises = this.$store.state.quiz.currentQuiz.bestFitFormEnterprisesIds.map(id => this.$store.getters.getFormOfEnterpriseById(id))
                    //get only the best 2
                    this.orderFormOfEnterprises = orderFormOfEnterprises.slice(0, 2)
                    this.$store.dispatch(types.STOP_LOADER, 'result')
                    return
                }

                let bestFitFormOfEnterprises = calculateBestFitFormOfEnterprise(
                    this.$store.state.quiz.currentQuiz.questionsAnswers,
                    this.$store.state.enterprise.formOfEnterprises,
                    this.$store.state.question.questions
                )
                let orderFormOfEnterprises = bestFitFormOfEnterprises.map(bestFitForm => this.$store.getters.getFormOfEnterpriseById(bestFitForm.formOfEnterpriseId))

                //get only the best 2
                this.orderFormOfEnterprises = orderFormOfEnterprises.slice(0, 2)

                //get only ids
                let bestFitOnlyIds = bestFitFormOfEnterprises.map(bestFitForm => bestFitForm.formOfEnterpriseId)
                this.$store.dispatch(types.SAVE_BEST_FIT_FORM_ENTERPRISES, bestFitOnlyIds)

                this.$store.dispatch(types.STOP_LOADER, 'result')
            })
        },
        goToFormEnterprise(url) {
            this.$router.push(url)
        },
        toggleAcceptToShareData(newValue) {
            this.$store.dispatch(types.MODIFY_CURRENT_QUIZ, {acceptToShareData: newValue})
        },
        submitQuestionnaireForm() {
            this.errors = {}

            let data = {
                email: this.email,
                regionId: this.regionId,
                ageId: this.ageId,
                genderId: this.genderId,
                acceptToShareData: this.acceptToShareData,
                zipcode: this.zipcode
            }

            let errors = validateQuestionnaireForm(data)
            this.errors = errors

            if (!isEmpty(errors)) {
                return
            }
            
            // Add locale related datas
            data.site = window.__initialData__.currentSite
            data.locale = this.$store.state.general.currentLang

            this.$store.dispatch(types.START_LOADER, 'result-form')
            this.$store.dispatch(types.FINISH_QUIZ, data)
                .then(() => {
                    this.success = true
                    this.$store.dispatch(types.STOP_LOADER, 'result-form')
                })

        },
    }

}
</script>
