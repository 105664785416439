import Vue from 'vue'
import VueResource from 'vue-resource'
import router, {resetRouter} from 'vue-config/router'
import Components from "./components";
import store from 'store/index'
import types from 'store/types'
import 'config/filters'

// Plugins
import VueMeta from 'vue-meta'
import VueGtm from 'vue-gtm'

import AppContainer from "components/containers/AppContainer";
import Translations from 'plugins/translate'
import 'assets/scss/main.scss'

import gsap from 'vendors/gsap'

Vue.use(VueResource)
Vue.use(Translations)


const isProd = process.env.NODE_ENV === 'production';
Vue.config.productionTip = false
Vue.http.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'

// Meta descriptions
Vue.use(VueMeta, {})

// Analytics
// Vue.use(VueAnalytics, {
//     id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
//     router,
//     debug: {
//         enabled: !isProd,
//         sendHitTask: isProd,
//     },
// });

// GTM
if ( isProd && process.env.VUE_APP_GTM ) {
    Vue.use(VueGtm, {
        id: process.env.VUE_APP_GTM,
        queryParams: {
            gtm_auth: process.env.VUE_APP_GTM_AUTH,
            gtm_cookies_win:'x'
        },
        defer: true,
        enabled: true,
        debug: false,
        vueRouter: router,
        loadScript: true,
        // ignoredViews: [''],
        // trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
    });
}

//mount the app on the specific div#boussole-app
new Vue({
    el: '#boussole-app',
    router: router,
    store: store,
    render: h => h(AppContainer),
    created() {
        resetRouter();
    }
})

window.Vue = Vue
