<template>
    <div class="form flex flex-col items-start justify-between w-full my-6">
        <div>
            <h1>{{form.title}}</h1>
            <div class="mt-2 mb-6" v-html="form.description"></div>
        </div>
        <arrow-btn v-on:onClick="goToFormOfEnterprise(form.url)"
                   :label="$t('Discover this form')"></arrow-btn>

    </div>
</template>

<script>
import ArrowBtn from "src/components/atoms/btns/ArrowBtn";
import __ from 'utils/lang'

export default {
    name: 'form-of-enterprise',

    components: {ArrowBtn},
    props: {
        form: {
            type: Object,
            required: true
        }
    },
    methods: {
        goToFormOfEnterprise(url) {
            this.$router.push(__(url))
        }
    }
}
</script>