<template>
    <div class="loading-container"
         :class="{isLoading:$store.state.general.loaderName.includes(loaderName)}">
        <div class="content">
            <slot></slot>
        </div>
        <loader></loader>
    </div>
</template>

<script>

import Loader from "components/atoms/Loader";
export default {
    name: 'loading-container',
    components: {Loader},
    props: ['loaderName']
}
</script>
