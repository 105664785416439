<template>
    <div class="vue-header-container">
        <div class="fake-header-height" ref="fakeHeader" v-if="showFakeHeader"></div>


        <div class="vue-header fixed" ref="realHeader">
            <div class="row justify-between items-stretch">
                <div class="col w-1/4 sm:w-1/6">
                    <router-link class="logo-container inline-block" to="/">
                        <sprite classes="text-current" id="logo-icon"></sprite>
                    </router-link>
                </div>
                <div class="col flex-1">
                    <div class="row w-full justify-end items-center">
                        <div class="hidden md:flex"
                             ref="desktopMenu"
                        >
                            <vue-menu classes="row"></vue-menu>
                        </div>

                        <div class="md:hidden">
                            <button type="button"
                                    class="c-btn__menu"
                                    @click.prevent="toggleMenu"
                                    :class="{'open': menuIsOpen}"
                            >
                                <span class="lines" ref="lines">
                                    <span ref="firstLine" class="line"></span>
                                    <span ref="lastLine" class="line"></span>
                                </span>
                                <span v-if="menuIsOpen" class="text-12">{{$t('Close')}}</span>
                                <span v-else class="text-12">{{$t('Menu')}}</span>
                            </button>
                        </div>
                    </div>
                </div>
                <socials class="mobile-hide"></socials>
            </div>
            <div class="relative w-full">

                <div class="hidden absolute top-0 w-full mt-12"
                     style="opacity: 0;"
                     ref="mobileMenu"
                >
                    <vue-menu
                            classes="flex flex-col items-center justify-center"
                            classesItem="py-6 border-b border-gray-yellow w-full px-6 text-center"
                            classesBtn="mt-12"
                    ></vue-menu>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import Sprite from "components/utils/Sprite";
import VueMenu from "components/atoms/Menu";

import {debounce, addClass, removeClass, hasClass} from "utils/utils";
import {EventBus, EVENTS} from "src/event-bus";
import VirtualKeyboardDetector from 'utils/virtualKeyboardDetector'
import Socials from "src/components/atoms/Socials";

export default {
    name: 'vue-header',
    components: {Socials, VueMenu, Sprite},
    data: function () {
        return {
            showFakeHeader: true,
            menuIsOpen: false,
            isClosing: false
        }
    },
    created() {

        this.onResize = this.onResize.bind(this)
        this.keyboardIsOpen = this.keyboardIsOpen.bind(this)
        this.keyboardIsClose = this.keyboardIsClose.bind(this)

        window.addEventListener('resize', debounce(this.onResize, 100));


        EventBus.$on(EVENTS.CLOSE_MENU, this.closeMenu)
        EventBus.$on(EVENTS.LOADER_HIDE_ENTIRE_PAGE, this.closeMenu)
        EventBus.$on(EVENTS.FAKE_RESIZE, this.onResize)

        VirtualKeyboardDetector.init();
        VirtualKeyboardDetector.on('virtualKeyboardVisible', this.keyboardIsOpen)
        VirtualKeyboardDetector.on('virtualKeyboardHidden', this.keyboardIsClose)
    },
    watch: {
        '$route'() {
            this.onResize();
        }
    },
    mounted() {
        this.onResize();

        if (!this.menuIsOpen) {
            this.closeMenuHeight = this.$refs.realHeader.clientHeight
        } else {
            this.closeMenuHeight = 0
        }
        this.halfLinesHeight = this.$refs.lines.clientHeight / 2

        TweenLite.set(this.$refs.firstLine, {top: this.halfLinesHeight - 4})
        TweenLite.set(this.$refs.lastLine, {top: this.halfLinesHeight + 4})
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize.bind(this));
        EventBus.$off(EVENTS.CLOSE_MENU, this.closeMenu)
        EventBus.$off(EVENTS.LOADER_HIDE_ENTIRE_PAGE, this.closeMenu)
        EventBus.$off(EVENTS.FAKE_RESIZE, this.onResize)

        VirtualKeyboardDetector.off('virtualKeyboardVisible', this.keyboardIsOpen)
        VirtualKeyboardDetector.off('virtualKeyboardHidden', this.keyboardIsClose)
    },
    methods: {
        keyboardIsOpen() {
            if (window.innerWidth < 768) {
                let _bottomContent = document.querySelector('.bottom-content')
                if (_bottomContent) {
                    addClass(_bottomContent, 'keyboard-mobile-open')
                }
            }
        },
        keyboardIsClose() {
            let _bottomContent = document.querySelector('.bottom-content')
            if (_bottomContent) {
                removeClass(_bottomContent, 'keyboard-mobile-open')
            }
        },
        onResize() {
            this.$nextTick(() => {
                if (this.$refs.fakeHeader && this.$refs.realHeader) {

                    if (!this.menuIsOpen && !this.isClosing) {
                        this.closeMenuHeight = this.$refs.realHeader.clientHeight
                    }
                    let closeRealMenuHeight = this.closeMenuHeight + 'px'
                    this.$refs.fakeHeader.style.height = closeRealMenuHeight
                    this.$refs.fakeHeader.style.marginTop = closeRealMenuHeight

                    let _loadingBar = document.querySelector('.loader-bar')
                    if (_loadingBar) {
                        _loadingBar.style.bottom = closeRealMenuHeight
                    }

                    //make sure the mobile menu is always height 100%
                    if (this.menuIsOpen) {
                        TweenLite.set(this.$refs.realHeader, {height: window.innerHeight})
                    }

                }

                if (this.$refs.realHeader && this.$refs.lines) {
                    this.halfLinesHeight = this.$refs.lines.clientHeight / 2
                }

                if (window.innerWidth > 768) {
                    if (this.menuIsOpen) {
                        this.closeMenu();
                    }
                    this.keyboardIsClose();
                }

                if (window.innerWidth > 768) {
                    //in desktop check if content is making the body scroll
                    let _contentsHeightCheck = [].slice.call(document.querySelectorAll('.content-height-issue'))
                    if (_contentsHeightCheck.length > 0) {
                        //take the last one
                        let _contentHeightCheck = _contentsHeightCheck[_contentsHeightCheck.length - 1]

                        //determine if we want to show the fake header, if content is bigger an viewport, show it
                        this.showFakeHeader = _contentHeightCheck.clientHeight > (window.innerHeight - (this.closeMenuHeight * 2));

                    }
                } else {
                    //always show it in mobile
                    this.showFakeHeader = true
                }

            })
        },
        closeMenu() {
            this.isClosing = true
            this.menuIsOpen = false

            TweenLite.to(this.$refs.mobileMenu, 0.25, {opacity: 0, display: 'none'})
            TweenLite.to(this.$refs.firstLine, 0.125, {transformOrigin: 'center center', rotation: 0})
            TweenLite.to(this.$refs.lastLine, 0.125, {transformOrigin: 'center center', rotation: 0})

            TweenLite.to(this.$refs.firstLine, 0.125, {top: this.halfLinesHeight - 4, delay: 0.125})
            TweenLite.to(this.$refs.lastLine, 0.125, {top: this.halfLinesHeight + 4, delay: 0.125})

            TweenLite.to(this.$refs.realHeader, 0.25, {
                height: this.closeMenuHeight, onComplete: () => {
                    this.isClosing = false
                }
            })

        },
        openMenu() {
            this.menuIsOpen = true

            TweenLite.to(this.$refs.mobileMenu, 0.25, {opacity: 1, display: 'block'})
            TweenLite.to(this.$refs.firstLine, 0.125, {top: this.halfLinesHeight})
            TweenLite.to(this.$refs.lastLine, 0.125, {top: this.halfLinesHeight})
            TweenLite.to(this.$refs.firstLine, 0.125, {transformOrigin: 'center center', rotation: 45, delay: 0.125})
            TweenLite.to(this.$refs.lastLine, 0.125, {transformOrigin: 'center center', rotation: -45, delay: 0.125})

            TweenLite.to(this.$refs.realHeader, 0.25, {height: window.innerHeight})

        },
        toggleMenu() {
            if (this.menuIsOpen) {
                this.closeMenu()
            } else {
                this.openMenu()
            }
        }
    }
}
</script>