<template>
    <button type="button" @click.prevent.stop="$emit('onClick')" @mouseenter="mouseEnter" @mouseleave="mouseLeave"
            class="c-btn c-btn__default" ref="arrowBtn">
        <span class="label">{{$t(label)}}</span>
        <span class="sprites-container">
            <span class="sprite-container sprite-container-black">
                <span class="sprite-container--inner sprite-container--inner-black |  text-black" ref="arrowBtnBlack">
                    <sprite id="long-arrow-right" color="black" ref="arrowBtnBlackSprite"></sprite>
                </span>
            </span>
            <span class="sprite-container sprite-container-white">
                <span class="sprite-container--inner sprite-container--inner-white | text-white" ref="arrowBtnWhite">
                    <sprite id="long-arrow-right" color="white" ref="arrowBtnWhiteSprite"></sprite>
                </span>
            </span>
        </span>
        <span class="bg" ref="arrowBtnBackground"></span>
    </button>
</template>
<script>
import Sprite from "components/utils/Sprite";

export default {
    name: 'arrow-btn',
    components: {Sprite},
    props: {
        label: String
    },
    created() {
        this.animationTime = 0.25
        this.animationTimeArrow = 0.25//will changes
        this.animationTimeArrowDelay = 0.1222//will changes
    },
    mounted() {


        // padding right + half with of arrow = Center of arrow
        let padding = window.getComputedStyle(this.$refs.arrowBtn, null).getPropertyValue('padding-left')
        padding = parseFloat(padding.replace('px', ''))

        this.sizeArrow = this.$refs.arrowBtnBlack.clientWidth
        this.halfSizeArrow = this.$refs.arrowBtnBlack.clientWidth / 2
        this.sizeX = padding + this.halfSizeArrow

        this.animationTimeArrow = this.animationTime / this.sizeX * this.halfSizeArrow
        this.animationTimeArrowDelay = this.animationTime - this.animationTimeArrow


        //init
        TweenLite.set(this.$refs.arrowBtnBackground, {right: this.sizeX})
        TweenLite.set(this.$refs.arrowBtnWhite, {width: this.halfSizeArrow})
        TweenLite.set(this.$refs.arrowBtnBlack, {width: this.halfSizeArrow})
    },
    methods: {
        mouseEnter() {
            if (!this.$refs.arrowBtnBackground) return

            TweenLite.set(this.$refs.arrowBtnBackground, {right: this.sizeX})
            TweenLite.to(this.$refs.arrowBtnBackground, this.animationTime, {right: 0})

            TweenLite.to(this.$refs.arrowBtnWhite, this.animationTimeArrow, {width: this.sizeArrow})

        },
        mouseLeave() {
            TweenLite.to(this.$refs.arrowBtnBackground, this.animationTime, {right: this.sizeX})
            TweenLite.to(this.$refs.arrowBtnWhite, this.animationTimeArrow, {
                width: this.halfSizeArrow,
                delay: this.animationTimeArrowDelay
            })
        }
    }
}
</script>