<template>
    <svg ref="svg" :data-id="id" :class="`relative icon-${id} sprite text-${color} ${classes}`">
        <use xmlns:xlink="http://www.w3.org/1999/xlink" :xlink:href="`#${id}`"></use>
    </svg>
</template>

<script>
export default {
    name: 'sprite',
    props: {
        id: {
            type: String,
            required: true
        },
        color: {
            type: String,
            default: ''
        },
        classes: {
            type:String,
            default:''
        }
    }
}
</script>

<style lang="scss">
	.sprite {
		&.icon-social-facebook {
			width: 1.5em;
			height: 1.5em;
		}
	}
</style>