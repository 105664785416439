import Vue from 'vue'
import {formatDate, includesAny} from 'utils/utils'

Vue.filter('lower', function (value) {
	if (!value) return ''
	value = value.toString()
	return value.toLowerCase()
})


Vue.filter('initial', function (value) {
	if (!value) return ''
	let initial = value[0]

	if (value.includes('-')) {
		initial += value[value.indexOf('-') + 1]
	}

	return initial.toUpperCase()
})

Vue.filter('formatDate', function (date) {
	return formatDate(date)
})

Vue.filter('replace', function (string, search, replace) {
	return string.replace(search, replace)
})
