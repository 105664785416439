<template>
    <div class="row grid_row grid-c"
         :class="classes">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'grid-row',
    props: {
        classes: {
            type: String
        }
    }
}
</script>