var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.progress > -1)?_c('div',{staticClass:"loader-bar fixed"},[_c('div',{ref:"bar",staticClass:"loader-bar__bar",style:(("width:" + _vm.loaderProgress + "%;"))},[_c('div',{ref:"tag",staticClass:"o-tag loader-bar-tag",class:{
             'o-tag--left': _vm.progress === 100,
             'o-tag--right': _vm.progress === 0,
             'o-tag--bottom': _vm.progress > 0 && _vm.progress < 100
             }},[_c('span',[_vm._v(_vm._s(_vm.text))])]),_c('div',{staticClass:"loader-bar__bar-progress",class:("bg-" + _vm.color)})]),_c('div',{staticClass:"fake w-full loader-bar-tag"},[_c('div',{ref:"text",staticClass:"o-tag",class:{
             'o-tag--left': _vm.progress === 100,
             'o-tag--right': _vm.progress === 0,
             'o-tag--bottom': _vm.progress > 0 && _vm.progress < 100
             }},[_c('span',[_vm._v(_vm._s(_vm.text))])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }