import types from 'store/types'
import {isEmpty} from 'utils/utils'
import ajax from 'utils/ajax'
import apiActions from 'config/apiActions'

// initial state, with craft backend or empty
const initData = JSON.parse(JSON.stringify(window.__initialData__))

const state = {
    initialDataIsFetch: false,

    pages: initData.pages || {},

    regions: initData.regions || [],
    genders: initData.genders || [],
    ages: initData.ages || []
}

// getters, make function easy to access by vue
//instead
const getters = {}

// actions
const actions = {
    [types.FETCH_INITIAL_DATA](store) {
        return new Promise((resolve, reject) => {
            // let dataPromise = store.dispatch(types.GET_DATA)
            let questionsPromise = store.dispatch(types.GET_QUESTIONS)
            let enterprisesPromise = store.dispatch(types.GET_FORM_OF_ENTERPRISES)

            Promise.all([
                //    dataPromise,
                questionsPromise,
                enterprisesPromise
            ])
                .then(() => {
                    resolve();
                    store.commit(types.FETCH_INITIAL_DATA, true)
                })
                .catch(() => {
                    reject();
                    store.commit(types.FETCH_INITIAL_DATA, false)
                    console.log('Error getting initial data')
                })
        })

    },
    [types.GET_DATA](store) {
        return
        /*

        return not necessary
        return new Promise((resolve, reject) => {
            ajax({
                url: apiActions.data.getAll,
                method: 'get'
            })
                .then(data => {
                    store.commit(types.GET_DATA, data.data)
                    resolve(data.data)
                })
                .catch(err => {
                    reject(err)
                })
        })

        */
    },
}

// mutations
const mutations = {
    [types.GET_DATA](state, data) {
        state.regions = data.regions
        state.genders = data.genders
        state.ages = data.ages
        state.initialDataIsFetch = true
    },
    [types.FETCH_INITIAL_DATA](state, isFetch) {
        state.initialDataIsFetch = isFetch
    }
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}
