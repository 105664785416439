//Error handling
const ERROR = 'ERROR'
const USER_ERROR = 'USER_ERROR'
const FETCH_INITIAL_DATA = 'FETCH_INITIAL_DATA'
const ROUTE_CHANGED = 'ROUTE_CHANGED'


const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'
//User Stuff
const MODIFY_USER = 'MODIFY_USER'

const GET_QUIZ_BY_UID = 'GET_QUIZ_BY_UID'

//Questions
const GET_QUESTIONS = 'GET_QUESTIONS'

const GET_FORM_OF_ENTERPRISES = 'GET_FORM_OF_ENTERPRISES'

//Loader
const START_LOADER = 'START_LOADER'
const STOP_LOADER = 'STOP_LOADER'
const STOP_INITIAL_LOADER = 'STOP_INITIAL_LOADER'
const STOP_ALL_LOADER = 'STOP_ALL_LOADER'
const STOP_PAGE_LOADER = 'STOP_PAGE_LOADER'
const START_PAGE_LOADER = 'START_PAGE_LOADER'

const GET_DATA = 'GET_DATA'

const SELECT_ANSWER = 'SELECT_ANSWER'
const ORDER_ANSWERS = 'ORDER_ANSWERS'
const MODIFY_CURRENT_QUIZ = 'MODIFY_CURRENT_QUIZ'
const RESET_CURRENT_QUIZ = 'RESET_CURRENT_QUIZ'
const INIT_QUIZ = 'INIT_QUIZ'
const UPDATE_QUIZ = 'UPDATE_QUIZ'
const COMPLETED_QUIZ = 'COMPLETED_QUIZ'
const FINISH_QUIZ = 'FINISH_QUIZ'
const SET_CURRENT_COLOR = 'SET_CURRENT_COLOR'
const FETCH_ENTERPRISE_HTML = 'FETCH_ENTERPRISE_HTML'
const TOGGLE_DROPDOWN = 'TOGGLE_DROPDOWN'
const SAVE_BEST_FIT_FORM_ENTERPRISES = 'SAVE_BEST_FIT_FORM_ENTERPRISES'
const LOAD_PAGES = 'LOAD_PAGES'

export default  {
	FETCH_ENTERPRISE_HTML,
	STOP_ALL_LOADER,
	SET_CURRENT_COLOR,
	STOP_PAGE_LOADER,
	LOAD_PAGES,
	START_PAGE_LOADER,
	MODIFY_CURRENT_QUIZ,
	GET_FORM_OF_ENTERPRISES,
	TOGGLE_DROPDOWN,
	INIT_QUIZ,
	UPDATE_QUIZ,
	COMPLETED_QUIZ,
	FINISH_QUIZ,
	GET_QUIZ_BY_UID,
	GET_DATA,
	ORDER_ANSWERS,
	RESET_CURRENT_QUIZ,
	STOP_INITIAL_LOADER,
	ERROR,
	ROUTE_CHANGED,
	SELECT_ANSWER,
	USER_ERROR,
	FETCH_INITIAL_DATA,
	GET_QUESTIONS,
	MODIFY_USER,
	SAVE_BEST_FIT_FORM_ENTERPRISES,
	CHANGE_LANGUAGE,
	START_LOADER,
	STOP_LOADER,
}
