<template>
    <div id="boussole-app">
        <current-color-styles></current-color-styles>

        <div class="relative min-h-screen h-auto" v-if="$store.state.general.isInitialLoading">
            <loading-page ref="initialLoadingPage"></loading-page>
        </div>
        <div v-else class="relative min-h-screen h-auto">
            <div v-if="$store.state.general.showPageLoader" ref="loader">
                <loading-between-page v-on:finish="onFinishPageLoader"></loading-between-page>
            </div>
            <div ref="content" class="h-full">
                <div class="main-content content-height-issue | h-full">
                    <div class="content h-full">
                        <router-view></router-view>
                    </div>

                    <vue-footer v-if="$route.meta.showFooter"></vue-footer>

                </div>
                <div class="bottom-content">
                    <div class="loader-bar-relative" v-if="$route.meta.showLoaderBar">
                        <loader-bar
                                :progress="$store.state.quiz.currentQuiz.progress"
                                :text="$store.state.quiz.currentQuiz.progressLabel"
                                color="current"
                        ></loader-bar>
                    </div>
                    <vue-header></vue-header>
                </div>

            </div>

        </div>

    </div>
</template>

<script>
import types from 'store/types'
import Sprite from "components/utils/Sprite";
import logo from 'assets/svg/logos/logo.svg'
import LoaderBar from "components/atoms/LoaderBar";
import {rand, removeClass} from 'utils/utils'
import VueHeader from "components/atoms/Header";
import LoadingPage from "components/pages/LoadingPage";
import CurrentColorStyles from "components/utils/CurrentColorStyles";
import VueFooter from "components/atoms/Footer";
import LoadingBetweenPage from "components/pages/LoadingBetweenPage";

export default {
    name: 'app-container',
    components: {LoadingBetweenPage, VueFooter, LoaderBar, CurrentColorStyles, LoadingPage, VueHeader, Sprite},
    data() {
        return {
            logo: logo,
        }
    },
    computed: {
        progressLabel() {
            if (this.$route.meta.requireQuizEmail) {
                return $store.state.quiz.currentQuiz.progressLabel
            }
            return $route.name
        }
    },
    methods: {
        onFinishPageLoader: function () {


            TweenLite.to(this.$refs.loader, 0.5, {
                opacity: 0
            })

            TweenLite.to(this.$refs.content, 0.5, {
                opacity: 1,
                delay: 0.25
            })

            setTimeout(() => {
                this.$store.dispatch(types.STOP_PAGE_LOADER)
                removeClass(document.body, 'no-scroll')
            }, 250)

        }
    },
    metaInfo() {
        return {
	        title: 'Chargement',
            titleTemplate: `%s - Boussole entrepreneuriale`
        }
    },
    mounted() {
        let promises = []

        if (this.$store.state.data.initialDataIsFetch) {
            return;
        }

        promises.push(this.$refs.initialLoadingPage.startLoader())
        promises.push(this.$store.dispatch(types.FETCH_INITIAL_DATA))


        Promise.all(promises)
            .then(() => {
                this.$store.commit(types.STOP_INITIAL_LOADER)
            })
            .catch(() => {
                this.$store.commit(types.STOP_INITIAL_LOADER)
            })
    },
}
</script>