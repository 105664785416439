<template>
    <div class="enterprise mb-12" v-if="legalForm">
        <go-back-header></go-back-header>
        <div class="container mx-auto">
            <div class="row justify-start">
                <div class="w-full md:w-7/12">
                    <div class="pull-left pull-left-current bg-current relative z-2">
                        <div class="bg-current py-6 pr-6">
                            <sprite :id="legalForm.typeOfEnterprise.spriteId" color="white"
                                    classes="w-12 h-12"></sprite>
                            <h5 class="text-white pb-1 pt-6"
                                v-if="legalForm.typeOfEnterprise.title !== legalForm.title">
                                {{legalForm.typeOfEnterprise.title}}
                            </h5>
                            <h1 class="text-white">{{legalForm.title}}</h1>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row py-12 items-start">
                <div class="w-full md:w-1/2">
                    <h1>{{$t('What is it')}} ?</h1>
                    <p class="mt-2" v-html="legalForm.completeDescription"></p>
                </div>
                <div class="w-full md:w-1/2 md:-mt-24 relative z-0">
                    <img v-if="legalForm.image" :src="legalForm.image" class="block w-full"/>
                    <img v-else-if="legalForm.typeOfEnterprise.image" :src="legalForm.typeOfEnterprise.image" class="block w-full"/>
                </div>
            </div>

            <builder-loop :builder="legalForm.builder"></builder-loop>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import {routes} from "config/routes";
import {isEmpty} from "utils/utils";
import types from 'store/types'
import Builder from "components/utils/Builder";
import Sprite from "components/utils/Sprite";
import LoadingContainer from "components/containers/LoadingContainer";
import GoBackHeader from "components/atoms/GoBackHeader";
import BuilderLoop from "components/builder/BuilderLoop";

export default {
    name: 'enterprise',
    components: {BuilderLoop, GoBackHeader, LoadingContainer, Sprite, Builder},
    data: function () {
        return {
            openDropdownIdx: 0
        }
    },
    watch: {
        '$route.params.slug'() {
            this.onRouteChange();
        }
    },
    computed: {
        legalForm() {
            return this.$store.getters.getFormOfEnterpriseBySlug(this.$route.params.slug)
        },
    },
    metaInfo() {
        return {
            title: this.legalForm.seo.titleRaw['1']
        }
    },
    created() {
        this.onRouteChange();

        this.$store.dispatch(types.SET_CURRENT_COLOR, this.$store.getters.getColorByName(this.legalForm.typeOfEnterprise.colorName))
    },
    methods: {
        openDropdown(idx) {
            this.openDropdownIdx = idx
        },
        onRouteChange() {
            if (isEmpty(this.$route.params.slug)) {
                this.$router.replace(routes.index)
            }

            this.openDropdownIdx = 0
        }
    }
}
</script>