<template>
    <div>
        <section v-if="!isDropdown" :id="`section-${id}`"
                 class="grid_container relative container mx-auto section"
                 :class="`bg-${color}`"
        >
            <slot></slot>
        </section>


        <div v-if="isDropdown" class="c-dropdown" :data-loop-idx="_uid"
        >
            <div class="c-dropdown--header" @click.prevent.stop="$store.dispatch('TOGGLE_DROPDOWN',_uid)">
                <div class="row w-full justify-between items-start">
                    <div class="col">
                        <h4>
                            {{ dropdownTitle }}
                        </h4>
                    </div>
                    <div class="col">
                        <sprite id="plus" color="black" classes="w-6 h-6"></sprite>
                    </div>
                </div>
            </div>
            <div ref="dropdown" class="overflow-hidden">
                <div class="c-dropdown--content">
                    <section :id="`section-${id}`" class="grid_container relative container mx-auto section"
                             :class="`bg-${color}`"
                    >
                        <slot></slot>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Sprite from "components/utils/Sprite";


export default {
    name: 'grid-container',
    components: {Sprite},
    props: {
        id: {
            type: String,
        },
        color: {
            type: String
        },
        isDropdown: {
            type: Boolean,
            default: false
        },
        dropdownTitle: {
            type: String
        }
    },
    mounted() {

	    if ( this.isDropdown )
		    window.TweenLite.set(this.$refs.dropdown, {height: 0})

    },
    watch: {
        //use the store so we can close dropdown/open dropdown from anywhere
        '$store.state.general.openDropdownIdx'(id) {
            if (id === this._uid) {
                window.TweenLite.set(this.$refs.dropdown, {height: 'auto'})
                window.TweenLite.from(this.$refs.dropdown, 0.5, {height: 0})
            } else {
                window.TweenLite.to(this.$refs.dropdown, 0.5, {height: 0})
            }
        }
    }
}
</script>