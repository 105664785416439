import Vue from 'vue'
import Router from 'vue-router'
import vueRoutes from 'config/vue-routes'
import {routes} from 'config/routes'
import {isEmpty, removeClass, addClass} from "utils/utils";
import store from 'store/index'
import types from 'store/types'
import {EventBus, EVENTS} from "src/event-bus";

Vue.use(Router)

//handle scroll with our code, not the default browser setup or vue.js
if (typeof history.scrollRestoration !== 'undefined') {
    history.scrollRestoration = 'manual'
}

const createRouter = () => new Router({
    base: window.__initialData__.currentBaseUrl,
    routes: vueRoutes,
    mode: 'history',  //remove # in the url bar,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash
                // , offset: { x: 0, y: 10 }
            }
        } else if (savedPosition) {
            return savedPosition
        } else {
            return {x: 0, y: 0}
        }
    }
})

const router = createRouter();



router.beforeEach((to, from, next) => {
    let isFirstLoading = from.name === null
	
	// Comment since a email/user isn’t required anymore
/*
    if (to.matched.some(record => record.meta.requireQuizEmail)) {
        if (isEmpty(store.state.quiz.currentQuiz.email)) {
            return next(routes.index)
        }
    }
*/

    let showPageLoader = true
    if (typeof to.meta.showPageLoader !== 'undefined') {
        showPageLoader = to.meta.showPageLoader
    }

    if (from.name !== to.name) {
         if (typeof to.meta.showPageLoader !== 'undefined' || typeof from.meta.showPageLoader !== 'undefined') {
             showPageLoader = true
         }
    }


    if (!isFirstLoading && showPageLoader) {
        store.dispatch(types.START_PAGE_LOADER)

        addClass(document.body, 'no-scroll')
        //wait for first loader to hide all content before changing
        let nextCallback = () => {
            next()
            EventBus.$off(EVENTS.LOADER_HIDE_ENTIRE_PAGE, nextCallback)
        }
        EventBus.$on(EVENTS.LOADER_HIDE_ENTIRE_PAGE, nextCallback)

    } else {
        next();
    }
})


router.afterEach((to, from, next) => {
    removeClass(document.body, 'page-' + from.name)
    addClass(document.body, 'page-' + to.name)
})


//to update routes...
export const resetRouter = () => {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher

    window.vueRouter = router
}


window.vueRouter = router

export default router
