import types from 'store/types'
import ajax from "utils/ajax";
import {routes} from "config/routes";
import apiActions from "config/apiActions";
import {QUESTION_TYPES} from "src/global";

// initial state, with craft backend or empty
const initData = JSON.parse(JSON.stringify(window.__initialData__))
const state = {
    formOfEnterprises: [],
    typeOfEnterprises: initData.typeOfEnterprises ? initData.typeOfEnterprises : [],
    isFetch: false,
}

// getters, make function easy to access by vue
//instead
const getters = {
    getFormOfEnterpriseById: state => id => state.formOfEnterprises.find(q => q.id === id),
    getFormOfEnterpriseBySlug: state => slug => state.formOfEnterprises.find(q => q.slug === slug),
    getFormOfEnterpriseByIdx: state => idx => state.formOfEnterprises.find(q => q.idx === parseInt(idx)),
    getTypeOfEnterpriseById: state => id => state.typeOfEnterprises.find(q => q.id === id),


}

// actions
const actions = {
    [types.GET_FORM_OF_ENTERPRISES](store) {
        if (store.state.isFetch) {
            return Promise.resolve(store.state.formOfEnterprises);
        }
        return new Promise((resolve, reject) => {
            ajax({
                url: apiActions.formOfEnterprises.getAll,
                method: 'get'
            })
                .then(data => {
                    if (data.success) {
                        resolve(data.formOfEnterprises)
                        let formOfEnterprises = data.formOfEnterprises
                        formOfEnterprises.forEach(formOfEnterprise => {
                            formOfEnterprise.typeOfEnterprise = store.getters.getTypeOfEnterpriseById(formOfEnterprise.typeId)
                        })

                        store.commit(types.GET_FORM_OF_ENTERPRISES, formOfEnterprises)

                    } else {
                        reject(data)
                    }
                })
                .catch(err => {
                    reject(err)
                })
        })

    },

}

// mutations
const mutations = {
    [types.GET_FORM_OF_ENTERPRISES](state, formOfEnterprises) {
        state.formOfEnterprises = formOfEnterprises
        state.isFetch = true

        let typeOfEnterprises = JSON.parse(JSON.stringify(state.typeOfEnterprises))
        typeOfEnterprises.forEach(type => type.formOfEnterprises = [])

        formOfEnterprises.forEach(form => {
            let typeForFormIdx = typeOfEnterprises.findIndex(type => type.id === form.typeId)
            if (typeForFormIdx !== -1) {
                typeOfEnterprises[typeForFormIdx].formOfEnterprises.push(form)
            }
        })

        state.typeOfEnterprises = typeOfEnterprises

    },
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}
