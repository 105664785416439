<template>
    <div class="grid_content"
         :class="classes">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'grid-content',
    props:{
        classes:{
            type:String
        }
    }
}
</script>