<script>
export default {
    name: 'block-heading',
    render: function (createElement) {
        return createElement(
            'div',
            {
                class: {
                    'block_heading': true
                }
            },
            [
                createElement(
                    this.tag,   // tag name
                    {
                        domProps:{
                            innerHTML: this.heading//add as innerHTML like v-html will do
                        }
                    }

                )
            ]
        )
    },
    props: {
        tag: {
            type: String,
            default: 'h1'
        },
        heading: {
            type: String
        }
    }
}
</script>