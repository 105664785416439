import types from 'store/types'
import ajax from "utils/ajax";
import apiActions from "config/apiActions";
// initial state, with craft backend or empty

const initData = JSON.parse(JSON.stringify(window.__initialData__))
const state = {
    isInitialLoading: true,
    showPageLoader: false,
    loaderName: [],

    currentLang: initData.currentLang,

    colors: initData.colors,
    currentColor: initData.currentColor,
    defaultColor: initData.defaultColor,

    defaultCraftUserId : initData.defaultCraftUserId,

    openDropdownIdx: 0,
}

// getters, make function easy to access by vue
//instead
const getters = {
    getColorByName: state => name => state.colors.find(c => c.name === name)
}

// actions
const actions = {
    [types.TOGGLE_DROPDOWN](store, idx) {
        store.commit(types.TOGGLE_DROPDOWN, idx)
    },
    [types.SET_CURRENT_COLOR](store, color) {
        store.commit(types.SET_CURRENT_COLOR, color)
    },
    [types.CHANGE_LANGUAGE](store, lang) {
        store.commit(types.CHANGE_LANGUAGE, lang)
    },
    [types.START_LOADER](store, loaderName) {
        store.commit(types.START_LOADER, loaderName)
    },
    [types.START_PAGE_LOADER](store) {
        store.commit(types.START_PAGE_LOADER)
    },
    [types.STOP_PAGE_LOADER](store) {
        store.commit(types.STOP_PAGE_LOADER)
    },
    [types.STOP_LOADER](store, loaderName) {
        store.commit(types.STOP_LOADER, loaderName)
    },
    [types.STOP_ALL_LOADER](store) {
        store.commit(types.STOP_ALL_LOADER)
    },
    [types.STOP_INITIAL_LOADER](store) {
        store.commit(types.STOP_INITIAL_LOADER)
    }
}

// mutations
const mutations = {
    [types.TOGGLE_DROPDOWN](state, idx) {
        //if click again, it will set it to null
        if (state.openDropdownIdx === idx) {
            state.openDropdownIdx = null
        } else {
            state.openDropdownIdx = idx
        }
    },
    [types.SET_CURRENT_COLOR](state, color) {
        state.currentColor = color
    },
    [types.CHANGE_LANGUAGE](state, lang) {
        state.currentLang = lang
    },
    [types.STOP_LOADER](state, loaderName) {

        let loaders = [...state.loaderName]
        let idx = loaders.findIndex(loader => loader === loaderName)
        if (idx === -1) return
        loaders.splice(idx, 1)
        state.loaderName = loaders
    },
    [types.STOP_ALL_LOADER](state) {
        state.isInitialLoading = false
        state.loaderName = []
    },
    [types.START_PAGE_LOADER](state) {
        state.showPageLoader = true
    },
    [types.STOP_PAGE_LOADER](state) {
        state.showPageLoader = false
    },
    [types.START_LOADER](state, loaderName) {

        let loaders = [...state.loaderName]
        let idx = loaders.findIndex(loader => loader === loaderName)
        if (idx > -1) return //loader already there, dont add twice
        state.loaderName.push(loaderName)
    },
    [types.STOP_INITIAL_LOADER](state) {
        state.isInitialLoading = false
    },
    [types.ROUTE_CHANGED](state) {
        state.openDropdownIdx = 0
    }
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}
