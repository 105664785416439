<template>
    <div class="go-back-header">
        <div class="w-full py-4 bg-gray-yellow">
            <div class="container mx-auto">
                <div class="flex flex-row justify-center">
                    <button type="button" @click.prevent="onClick" class="c-btn c-btn__text">
                        <sprite id="long-arrow-right" classes="flip-y mr-2 w-8 text-black"></sprite>
                        <span class="font-semi-bold text-black">{{$t(label)}}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Sprite from "components/utils/Sprite";
import __ from 'utils/lang'

export default {
    name: 'go-back-header',
    components: {Sprite},
    props: {
        route: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: 'Go back'
        }
    },
    methods: {
        onClick() {
			
			// Add fallback for Result to avoid going back to the last question
	        if ( this.$router && this.$router.currentRoute.name === 'result') {
				this.$router.push('/')
				return
	        }

            if (this.route) {
                this.$router.push(__(this.route))
                return
            }

            //go back to past page or index if no last page
            let numberOfEntries = window.history.length;
            if (numberOfEntries > 1) {
                this.$router.go(-1)
            } else {
                this.$router.push('/')

            }
        }
    }
}
</script>