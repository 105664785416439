const apiActions = {

    questions: {
        getAll: '/actions/module/data/get-questions'
    },
    quiz: {
        create: '/actions/module/questionnaire/create',
        update: '/actions/module/questionnaire/update',
        completed: '/actions/module/questionnaire/mark-completed',
        finish: '/actions/module/questionnaire/finish',
        getAll: '/actions/module/questionnaire/get-all',
        getAllByEmail: '/actions/module/questionnaire/get-all-by-email',
        getByUid: '/actions/module/questionnaire/get-by-uid',
        saveBestFit: '/actions/module/questionnaire/save-best-fit',
    },
    formOfEnterprises: {
        getAll: '/actions/module/data/get-form-of-enterprises'
    }
}

export default apiActions
