import {QUESTION_TYPES} from 'src/global'

export const calculateBestFitFormOfEnterprise = (questionsAnswers, formOfEnterprises, allQuestions) => {

    //helper functions
    const getQuestionById = (id) => {
        return allQuestions.find(q => q.id === id)
    }

    const getFormOfEnterpriseById = (id) => {
        return formOfEnterprises.find(q => q.id === id)
    }

    const getAnswerById = (id) => {
        let answer = null
        allQuestions.forEach((question) => {
            question.answers.forEach(_answer => {
                if (_answer.id === id) {
                    answer = _answer
                }
            })
        })

        return answer;
    }


    //make sure vue.js is not involved and will not react to changes
    questionsAnswers = JSON.parse(JSON.stringify(questionsAnswers))
    formOfEnterprises = JSON.parse(JSON.stringify(formOfEnterprises))
    allQuestions = JSON.parse(JSON.stringify(allQuestions))


    let bestFitForms = []

    //init with 0
    formOfEnterprises.forEach(formOfEnterprise => {
        bestFitForms.push({
            formOfEnterpriseId: formOfEnterprise.id,
            bestFitValue: 0
        })
    })

    Object.keys(questionsAnswers).forEach(questionId => {
        let question = getQuestionById(questionId)

        //not normal!!
        if (!question) {
            console.error('QUESTION NOT FOUND', question)
            return
        }

        if (question.type === QUESTION_TYPES.NORMAL) {
            let answerId = questionsAnswers[questionId]
            let answer = getAnswerById(answerId)

            //not normal!!
            if (!answer) {
                console.error('ANSWER NOT FOUND', answerId)
                return
            }

            bestFitForms.forEach(bestFitFormData => {
                bestFitFormData.bestFitValue += answer.weights[bestFitFormData.formOfEnterpriseId] * question.multiplier
            })

        }

        if (question.type === QUESTION_TYPES.DRAG_AND_DROP) {
            let answerIds = questionsAnswers[questionId]

            let answers = answerIds.map(answerId => getAnswerById(answerId))

            //not normal!!
            if (answers.includes(undefined) || answers.includes(null)) {
                console.error('ANSWER NOT FOUND', answers)
                return
            }

            bestFitForms.forEach(bestFitFormData => {
                answers.forEach((answer, answerIdx) => {
                    let strength = answers.length - answerIdx//the first is stronger than the last one
                    let quotient = answers.length * (answers.length + 1) / 2 // allow to divide so its could be equal like 1 answer
                    bestFitFormData.bestFitValue += (answer.weights[bestFitFormData.formOfEnterpriseId] * question.multiplier * strength) / quotient
                })
            })

        }


    })


    //order bigger to the smallest fit value
    let bestFitFormsOrdered = bestFitForms.sort((a, b) => b.bestFitValue - a.bestFitValue)

    return bestFitFormsOrdered


}