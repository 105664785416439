<template>
    <div class="loading-between-page" ref="container">
        <div class="initial-loader h-screen w-screen fixed inset-0 z-hover">
            <div ref="bgBlueDark" class="absolute inset-0 h-screen w-screen bg-blue-dark"></div>
            <div ref="bgBlue" class="absolute inset-0 h-screen w-screen bg-blue"></div>
            <!-- <div ref="bgRed" class="absolute inset-0 h-screen w-screen bg-red"></div> -->
            <div ref="logo" class="absolute inset-0 h-screen w-screen flex flex-row justify-center items-center">
                <img alt="boussole" :src="logo"/>
            </div>
            <div ref="bgWhite" class=" absolute bottom-0 left-0 right-0 bg-white w-screen h-screen"></div>
        </div>
    </div>
</template>

<script>
import {EventBus, EVENTS} from 'src/event-bus';
import Sprite from "components/utils/Sprite";
import logo from 'assets/svg/logos/logo.svg'

export default {
    name: 'loading-between-page',
    components: {Sprite},
    props: {
        time: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            logo: logo
        }
    },
    mounted() {
        //TweenLite.set(this.$refs.container, {opacity:0})
        this.start(this.time);
    },
    methods: {
        start(time = null) {
            if (!time) {
                time = 2//2 seconds
            }

            let $refs = [
                this.$refs.bgBlueDark,
                this.$refs.bgBlue,
                //this.$refs.bgRed,
                this.$refs.bgWhite,
            ]


            let totalCount = $refs.length
            let timePerSlide = time / totalCount


            TweenLite.set(this.$refs.logo, {zIndex: 25, height: window.innerHeight, opacity: 0})
            TweenLite.to(this.$refs.logo, 0.5, {opacity: 1})


            $refs.forEach((bgNode, idx) => {
                let isPairKey = idx % 2 === 0 ? 'yPercent' : 'yPercent'
                TweenLite.set(bgNode, {[isPairKey]: -100, zIndex: (idx + 1) * 10, height: window.innerHeight})

                let data = {[isPairKey]: 0, delay: ((timePerSlide / 2) * idx)}

                if (idx + 1 === totalCount) {
                    data['onComplete'] = () => {
                        this.$emit('finish')
                    }
                    //TweenLite.to(bgNode, timePerSlide, {height:0, delay:((timePerSlide / 2) * idx)})
                }

                if (idx === 0) {
                    data['onComplete'] = () => {
                        EventBus.$emit(EVENTS.LOADER_HIDE_ENTIRE_PAGE)
                    }
                }

                TweenLite.to(bgNode, timePerSlide, data)
            })

        }
    }
}
</script>