<script>
import GridContent from "components/builder/GridContent";
import GridRow from "components/builder/GridRow";
import GridContainer from "components/builder/GridContainer";
import BlockContent from "components/builder/BlockContent";
import BlockHeading from "components/builder/BlockHeading";
import BlockLinks from "components/builder/BlockLinks";
import BlockSpacer from "components/builder/BlockSpacer";
import BlockImage from "components/builder/BlockImage";
import BlockList from "components/builder/BlockList";

const BUILDER_COMPONENTS = {
    'grid_content': GridContent,
    'grid_row': GridRow,
    'grid_container': GridContainer,
    'block_content': BlockContent,
    'block_heading': BlockHeading,
    'block_links': BlockLinks,
    'block_list': BlockList,
    'block_spacer': BlockSpacer,
    'block_image':BlockImage
}
export default {
    name: 'builder-loop',
    props: {
        builder: {
            type: Array
        }
    },
    render: function (createElement) {
        let builder = []

        //recusive render children
        let renderChildrens = (data) => {
            let childrens = []
            if (data.childrens.length > 0) {
                data.childrens.forEach((item, idx) => {
                    let itemChilds = []
                    if (item.childrens.length > 0) {
                        itemChilds.push(renderChildrens(item))
                    }
                    childrens.push(
                        createElement(
                            BUILDER_COMPONENTS[item.blockType],
                            {
                                props: item.props
                            },
                            itemChilds
                        )
                    )

                })

            }
            return childrens;
        }

        //render level 1 + children
        this.builder.forEach(block => {
            builder.push(
                createElement(
                    BUILDER_COMPONENTS[block.blockType],
                    {
                        props: block.props
                    },
                    renderChildrens(block)
                )
            )
        })

        //render entire dom tree
        return createElement(
            'div',
            {
                class: {
                    'builder': true
                }
            },
            builder
        )
    },
}
</script>