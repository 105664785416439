<template>
    <div class="checkbox label" :class="`checkbox-size--${size}`">
        <div>
            <div class="checkbox-item">
                <input
                        :id="`checkbox-${_uid}`"
                        type="checkbox"
                        :checked="value"
                        :class="{'checked': value}"
                >
                <span class="inner" :class="`bg-${color}`"></span>
                <span class="outer"></span>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'checkbox',
    props: {
        value: {
            type: Boolean,
            required: true
        },
        size: {
            type: String,
            default: 'normal'
        },
        color: {
            type: String,
            default: 'black'
        }
    }
}
</script>
