<template>
    <img class="block w-full" :alt="title" :src="url">
</template>

<script>
export default {
    name: 'block_image',
    props: {
        title: {
            type: String,
        },
        url: {
            type: String
        }
    }
}
</script>