<template>
    <div class="enterprises mb-12">
        <page-header v-if="pageData" :image="pageData.image" :title="pageData.title"></page-header>
        <div class="container mx-auto">
            <div
                    class="mb-24"
                    v-for="type in $store.state.enterprise.typeOfEnterprises"
                    :key="type.id"
            >
                <div class="row justify-start">
                    <div class="w-full md:w-7/12">
                        <div class="pull-left relative z-2"
                             :class="`bg-c-${type.colorName} pull-left-c-${type.colorName}`">
                            <div class=" py-6 pr-6" :class="`bg-c-${type.colorName}`">
                                <sprite :id="type.spriteId" color="white" classes="w-12 h-12"></sprite>
                                <h1 class="text-white mt-2">{{type.title}}</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content">
                    <div class="row justify-end md:justify-between items-start">
                        <div class="w-full md:w-1/2 mt-12">
                            <div v-if="type.description">
                                <h1>{{$t('What is it')}} ?</h1>
                                <div class="my-2" v-html="type.description"></div>
                            </div>
                            <div v-if="type.formOfEnterprises.length > 0">
                                <form-of-enterprise :form="type.formOfEnterprises[0]"
                                class="hidden md:block"
                                >
                                </form-of-enterprise>
                            </div>
                        </div>
                        <div class="w-2/3 -mt-12 md:w-4/12 md:-mt-24 relative z-0">
                            <img :src="type.image" class="block w-full"/>
                        </div>
                    </div>
                </div>
                <div class="forms grid-c row items-stretch w-full -mt-6 md:mt-0"
                     :class="{
                'justify-between': type.formOfEnterprises.length > 1,
                'justify-start': type.formOfEnterprises.length === 1
                }"
                >
                    <div class="w-full md:w-6/12"
                         v-for="(form, idx) in type.formOfEnterprises"
                         :key="form.id"
                         :class="{'block md:hidden': idx === 0}"
                    >
                        <form-of-enterprise :form="form"></form-of-enterprise>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import GoBackHeader from "components/atoms/GoBackHeader";
import Sprite from "components/utils/Sprite";
import ArrowBtn from "components/atoms/btns/ArrowBtn";
import __ from 'utils/lang'
import FormOfEnterprise from "src/components/atoms/FormOfEnterprise";
import PageHeader from "components/atoms/PageHeader";

export default {
    name: 'enterprises',
    components: {PageHeader, FormOfEnterprise, ArrowBtn, Sprite, GoBackHeader},
    data() {
        return {
	        pageId: 12833,
            pageData: null
        }
    },
    metaInfo() {
        return {
            title: this.pageData.seo.title
        }
    },
    created() {

        try {
            this.pageData = this.$store.state.data.pages[this.pageId]
        } catch (e) {
	        console.log(e)
        }
    }
}
</script>
