import types from 'store/types'
import ajax from "utils/ajax";
import {routes} from "config/routes";
import apiActions from "config/apiActions";
import {QUESTION_TYPES} from "src/global";

// initial state, with craft backend or empty
const state = {
    questions: [],
    isFetch: false,
}

// getters, make function easy to access by vue
//instead
const getters = {
    getQuestionById: state => id => state.questions.find(q => q.id === id),
    getQuestionByIdx: state => idx => state.questions.find(q => q.idx === parseInt(idx)),
    getAnswerById: state => id => {
        let answer = null
        state.questions.forEach((question) => {
            question.answer.forEach(_answer => {
                if (_answer.id === id) {
                    answer = _answer
                }
            })
        })

        return answer;
    }
}

// actions
const actions = {
    [types.GET_QUESTIONS](store) {
        if (store.state.isFetch) {
            return Promise.resolve(store.state.questions);
        }
        return new Promise((resolve, reject) => {
            ajax({
                url: apiActions.questions.getAll,
                method: 'get',
            })
                .then(data => {
                    if (data.success) {
                        resolve(data.questions)
                        store.commit(types.GET_QUESTIONS, data.questions)

                        // init quiz
                        data.questions.forEach(q => {
                            if (q.type === QUESTION_TYPES.NORMAL) {
                                // set id
                                store.commit(types.SELECT_ANSWER, {questionId: q.id, answerId: -1})
                            } else if (q.type === QUESTION_TYPES.DRAG_AND_DROP) {
                                // order by default ids
                                let answerIds = q.answers.map(a => a.id)
                                store.commit(types.ORDER_ANSWERS, {questionId: q.id, answerIds: answerIds})
                            } else {
                                //console.error('INVALID QUESTION TYPE FOR', q)
                            }
                        })

                    } else {
                        reject(data)
                    }
                })
                .catch(err => {
                    reject(err)
                })
        })

    },

}

// mutations
const mutations = {
    [types.GET_QUESTIONS](state, questions) {
        state.questions = questions
        state.isFetch = true
    },
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}
