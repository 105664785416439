<template>
    <div class="col socials">
	    <div class="px-8 py-6 | bg-current">
	        <ul class="row  justify-center items-center">
	            <li class="social" v-for="social in socials" :key="social.title">
	                <a :href="social.socialLink" target="_blank">
	                    <sprite class="hover:opacity-75" color="white" :id="`social-${social.socialTitle.toLowerCase()}`"></sprite>
	                </a>
	            </li>
	        </ul>		    
	    </div>
    </div>
</template>

<script>
import Sprite from "src/components/utils/Sprite";
	
export default {
    name: 'Socials',
    components: {Sprite},
    data() {
        return {
            socials: JSON.parse(JSON.stringify(window.__initialData__.socials))
        }
    }
}
</script>